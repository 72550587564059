import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import one from "../../assets/eventsKid.jpeg";
import two from "../../assets/fountain.jpeg";
import three from "../../assets/afromen.jpeg";
import four from "../../assets/womenCamel.jpeg";

const header = [one, two, three, four];

export default function Culture() {
  const [culture, setCulture] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [headers, setHeader] = useState(0);
  const location = useLocation();
  function generateRandom(maxLimit = header.length) {
    let rand = Math.random() * maxLimit;
    console.log(rand); // say 99.81321410836433

    rand = Math.floor(rand); // 99

    return rand;
  }
  useEffect(() => {
    setCulture(location.state.name);
    var i = generateRandom();
    setHeader(i);
    setLoaded(true);
  }, []);

  return (
    <div className="flex flex-col justify-between -mb-5">
      {loaded ? (
        <div>
          <div
            style={{
              backgroundImage: `url(${header[headers]})`,
              backgroundSize: "cover",
            }}
          >
            <div className="w-screen h-hero bg-otctrans">
              <div className="max-w-4xl p-2 lg:p-20">
                <h1 className="text-xs text-white mb-5 mt-32 lg:mt-80">
                  {culture.title}
                </h1>
                <h1 className="text-3xl text-primary">
                  Explore Oromia's Diverse Beauty
                </h1>
                <p className="text-white text-xs">{culture.description}</p>
              </div>
            </div>
          </div>
          <div className="h-auto">
            {culture.story.map((item, index) => (
              <div className="lg:ml-24 lg:mr-24 ml-2 mr-2 mt-10 mb-3">
                <p
                  className="text-black font-description first-line:uppercase first-line:tracking-widest
                      first-letter:text-7xl first-letter:font-bold first-letter:text-primary
                      first-letter:mr-3 first-letter:float-left"
                >
                  {item.description}
                </p>
                <div className="flex flex-row flex-wrap">
                  {item.images.map((image, index) => (
                    <img
                      src={"https://otc.visitoromia.org" + image.image}
                      className="w-96 mr-5 mb-5"
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
