import { height } from "@mui/system";
import React, { useState, useEffect } from "react";
import animal from "../../assets/forest.jpg";

export default function NavDetail() {
  const [data, setData] = useState([{}, {}, {}, {}]);
  return (
    <div className=" h-screen flex flex-1 flex-col overflow-x-hidden">
      <div
        style={{
          backgroundImage: `url(${animal})`,
          backgroundSize: "cover",
          backgroundRepeat: "space",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="bg-shallow w-screen  p-14 flex flex-col">
          <div className=" w-screen   flex flex-row">
            {data.map((item, index) => (
              <div className="bg-white w-80 h-96 m-2  shadow-xl  shadow-black ">
                <img src={animal} />
                <h1 className="text-xl text-primary capitalize p-2">
                  title here
                </h1>
                <p className="text-xs p-2">
                  Lorem esse ad excepteur consequat exercitation eu dolore sint.
                  Aliquip laboris nisi nisi consequat ipsum consequat id mollit
                  amet consectetur occaecat. Enim aute et Lorem exercitation
                  anim duis laborum minim cupidatat laboris.
                </p>
                <button className="text-white bg-primary m-2 p-2 mt-10">
                  Email For more information
                </button>
              </div>
            ))}
          </div>
          <div>
            <h1>The main title goes here</h1>
          </div>
        </div>

        {/* <img src={animal} className="w-screen h-screen" /> */}
      </div>
      {/* <div className="bg-black w-screen h-96 rounded-b-2xl"></div> */}
    </div>
  );
}
