import React, { useState, useEffect } from "react";
import one from "../../assets/hotel.jpg";
import three from "../../assets/bg.jpg";
import { useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactStars from "react-rating-stars-component";
import GoogleMapReact from "google-map-react";
import "../../App.css";

import ReactPlayer from "react-player";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import Footer from "../Footer";

const AnyReactComponent = ({ text }) => (
  <div className="hover:bg-primary w-[200px] text-2xl h-[150px] rounded-lg text-primary hover:text-white p-4">
    {text}
  </div>
);

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsives = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function HeroDetail() {
  const [data, setData] = useState([{}, {}, {}, {}]);
  const [text, setText] = useState("");
  const [activity, setActivity] = useState([]);
  const [vedio, setVedio] = useState([]);
  const [packages, setPackage] = useState([]);
  var [a, setA] = useState(0);
  var [i, setI] = useState(0);
  var [h, setH] = useState(0);
  const [hotel, setHotels] = useState([]);
  const location = useLocation();
  const [main, setMain] = useState({});
  const [loaded, setLoaded] = useState(false);

  const addHotel = () => {
    setH(h + 1);
  };

  const subHotel = () => {
    setH(h - 1);
  };
  const changeDest = (value) => {
    console.log(value);
    fetchSliders(value);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const fetchSliders = async (slug) => {
    try {
      const response = await fetch(
        `https://otc.visitoromia.org/api/dest/${slug}`
      );
      const res = await response.json();
      console.log("check", res.activities);
      setData(res.others);
      setActivity(res.activities);
      setA(0);
      setHotels(res.hotel);
      setPackage(res.packages);
      setVedio(res.vedios);
      setMain(res);
      setLoaded(true);
      console.log(res);
    } catch (e) {
      console.log(e);
      setLoaded(false);
    }
  };
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  const giveRate = (own, value) => {
    var myHeaders = new Headers();
    var token = localStorage.getItem("access_token");
    var raw = JSON.stringify({ owner: own, rate: value });
    myHeaders.append("Authorization", "Bearer  " + token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch("https://otc.visitoromia.org/api/give_rate/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        apiCall();
      })
      .catch((error) => {
        console.log("rating error: ", error);
      });
  };
  const changeMain = (value) => {};
  const textGeter = async () => {
    try {
      const response = await fetch("https://otc.visitoromia.org/api/text/11");
      const res = await response.json();
      setText(res);
    } catch (error) {
      console.log(error);
    }
  };
  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };
  const center = {
    lat: 59.95,
    lng: 30.33,
  };
  async function apiCall() {
    try {
      const response = await fetch("https://otc.visitoromia.org/api/package");
      const res = await response.json();
      //   setData(res);
    } catch (e) {}
  }
  useEffect(() => {
    apiCall();
    setA(0);
    textGeter();
    fetchSliders(location.state.slug);
  }, []);
  return (
    <div class="scroll-smooth z-20">
      {loaded ? (
        <div className="flex flex-row justify-center lg:-mt-0   h-[65vh] lg:h-[auto] ">
          {vedio[0] ? (
            <ReactPlayer
              className="lg:w-[100vw] w-[10vw] -mt-[30vh] h-hero rounded-b-lg"
              url={vedio[0].link}
              playing={true}
              muted={true}
              loop={false}
              controls={false}
              pip={false}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    fs: 0,
                  },
                },
              }}
              width={"100vw"}
              height={"100vh"}
            />
          ) : null}
        </div>
      ) : null}
      <div className="flex flex-row w-[100vw] mb-20 lg:mb-0 ">
        <div className=" lg:p-20 p-3 flex lg:flex-row flex-col 2xl:flex-row  -mt-0 lg:-mt-10  max-w-[110vw]">
          <div className="flex flex-col">
            {loaded ? (
              <div className="object-fill lg:mr-10 w-[94vw]">
                <h1 className="lg:text-lg text-primary pt-0 2xl:text-2xl  font-sanRegular">
                  {main.catagory.name}
                </h1>
                <h1 className="lg:text-2xl text-xl 2xl:text-3xl  font-sanbold">
                  {main.name}
                </h1>

                <img
                  src={main.image[i].image}
                  className="object-cover lg:w-[50vw]  h-[auto] lg:h-twoImage lg:max-h-hero h-60 shadow-xl shadow-black rounded-lg"
                />
                <p
                  className="text-sm max-w-[90vw] 2xl:max-w-3xl 2xl:text-sm text-justify pt-10 mb-0  lg:max-w-[50vw]  
                       first-letter:font-bold first-letter:text-primary
                       first-letter:float-left   font-sanRegular backdrop-blur-sm bg-white/30"
                >
                  {main.description}
                </p>

                <div>
                  {i > 0 ? (
                    <button
                      onClick={() => setI(i - 1)}
                      className="bg-primary text-white p-2 my-1 mt-5 rounded-sm mr-8 hover:bg-white hover:text-black hover:border-2 hover:border-primary hover:p-[6px]  font-sanRegular"
                    >
                      Back
                    </button>
                  ) : null}
                  {i < main.image.length - 1 ? (
                    <button
                      onClick={() => setI(i + 1)}
                      className="bg-primary text-white p-2 my-1 mt-5 rounded-sm hover:bg-white hover:text-black hover:border-2  hover:border-primary hover:p-[6px] transition-colors duration-[1s]  font-sanRegular"
                    >
                      Next
                    </button>
                  ) : null}
                </div>
              </div>
            ) : null}
            {loaded ? (
              <>
                {activity.length > 0 ? (
                  <div className="w-[94vw] mt-10">
                    <h1 className="text-lg text-primary font-sanRegular">
                      Activity
                    </h1>
                    <h1 className="lg:text-2xl text-xl  font-sanbold">
                      {activity[a].title}
                    </h1>
                    <img
                      src={"https://otc.visitoromia.org" + activity[a].image}
                      className="object-cover  lg:w-[50vw] lg:h-[57vh]  h-[30vh] shadow-xl shadow-black border-4  transition-opacity rounded-lg"
                    />
                    <br />
                    <br />
                    <article class="prose lg:prose-xl">
                      <p
                        className="lg:text-sm  w-[100vw] mb-10 text-xs max-w-[90vw] lg:max-w-[50vw]  2xl:max-w-3xl 2xl:text-sm text-justify   first-line:tracking-widest font-sanRegular
                       first-letter:font-bold first-letter:text-primary
                      first-letter:float-left"
                      >
                        {activity[a].description}
                      </p>
                    </article>

                    {a < activity.length - 1 ? (
                      <button
                        onClick={() => setA(a + 1)}
                        className="bg-primary text-white p-2 my-1 mt-5 rounded-sm mr-8 hover:bg-white hover:text-black hover:border-2 hover:border-primary hover:p-[6px]  font-sanRegular"
                      >
                        Next
                      </button>
                    ) : null}
                    {a > 0 ? (
                      <button
                        onClick={() => setA(a - 1)}
                        className="bg-primary text-white p-2 my-1 mt-5 rounded-sm mr-8 hover:bg-white hover:text-black hover:border-2 hover:border-primary hover:p-[6px]  font-sanRegular"
                      >
                        Back
                      </button>
                    ) : null}
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
        <div className=" w-[20vw]  mt-28 invisible lg:visible -ml-[35vw] sticky top-0 right-0   h-[100vh]">
          <div className="h-[40vh] -ml-52 rounded-lg">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyBJliyWS8E2mzOFmKpKJgJT2iUPX2m6G9M",
              }}
              className="border-2 border-primary rounded-2xl bg-black"
              defaultCenter={{ lat: 9.005401, lng: 38.763611 }}
              defaultZoom={6}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
              <AnyReactComponent
                lat={main.Latitude}
                lng={main.Longitude}
                text={main.name}
              />
            </GoogleMapReact>
          </div>
          <div className="h-[auto] w-[30vw] -ml-52 border-l-2 border-[0px] border-primary">
            <ul>
              {data.map((item, index) => (
                <li className="text-lg p-2 uppercase hover:bg-primary hover:text-white rounded-md cursor-pointer transition-transform font-sanbold ">
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div
        className="w-screen h-imagebg bg-black mb-52 mt-0"
        style={{
          backgroundImage: `url(${three})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="bg-otctrans w-screen h-imagebg lg:pl-0 lg:pr-0 -mt-20 lg:-mt-0 ">
          <h1 className="text-white font-sanbold shadow-2xl pt-10 text-4xl pb-5 2xl:text-5xl ml-2 lg:ml-20 ">
            Packages
          </h1>
          <p className=" text-white font-sanRegular lg:text-lg pb-2 ml-2 lg:ml-20  ">
            Oromia has multiple Package Providers with ranging activities
          </p>
          <div className="lg:ml-20 lg:mr-20">
            <Carousel responsive={responsives}>
              {packages.map((item, index) => (
                <div
                  className="mb-20  w-[94vw] h-[400px]  lg:h-threeImage lg:w-[29vw]  bg-whiteshadow-xl shadow-black flex flex-col justify-end rounded-lg"
                  style={{
                    backgroundImage: `url(${
                      "https://otc.visitoromia.org/" + item.image
                    })`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className=" p-2 hover:h-[50vh] transition duration-0 delay-500 ease-in  rounded-lg backdrop-blur-sm bg-otctrans/30">
                    <div className="myDIV">
                      <h1 className="text-white mb-2   hover:text-primary hover:decoration-white lg:text-xl font-sanbold">
                        {item.title}
                      </h1>
                      {/* <p className="text-xs">{item.description}</p> */}
                      <h1 className="text-lg text-white 2xl:text-lg font-sanRegular">
                        {item.duration} package with {item.accomudation}{" "}
                        accomudation and {item.transportation}
                      </h1>
                      <a
                        href={item.link}
                        className="text-primary mt-4 hover:text-white lg:text-lg hover:hidden transition-opacity cursor-pointer py-10 duration-500"
                        target={"_blank"}
                      >
                        More
                      </a>
                    </div>
                    <p className="hide">{item.description}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      {hotel.length > 0 ? (
        <div className="w-screen">
          <h1 className="flex justify-center text-xl  lg:text-5xl space-x-5 font-extrabold text-primary -mt-10 font-sanbold">
            Accommodations
          </h1>
          <h1 className="text-center font-sanRegular text-xl">
            Pick a place to stay
          </h1>
          <div
            className="w-[100vw]  bg-black h-hero "
            style={{ backgroundImage: `url(${one})`, backgroundSize: "cover" }}
          >
            <div className="bg-otctrans w-screen flex flex-row justify-center items-center  h-hero">
              {h > 0 ? (
                <a
                  onClick={() => subHotel()}
                  className="border-2 border-primary mr-2 hover:border-white ml-2 lg:ml-24 rounded-2xl"
                >
                  <FaAngleLeft color="red" size={"4vw"} />
                </a>
              ) : (
                <a className="border-2 border-white mr-2 hover:border-white ml-2 lg:ml-24 rounded-2xl ">
                  <FaAngleLeft color="white" size={"4vw"} />
                </a>
              )}
              <div
                className="bg-primary lg:w-[120vh w-[100vw] mt-10 h-less  shadow-2xl shadow-black  flex flex-row justify-end rounded-lg"
                style={{
                  backgroundImage: `url(${
                    "https://otc.visitoromia.org" + hotel[h].company_logo
                  })`,
                  backgroundSize: "cover",
                }}
              >
                <div className="hover:w-[50%] lg:p-20 p-10 w-[100%] transition-all ease-in-out duration-150 backdrop-blur-sm bg-white/30 rounded-lg">
                  <h1 className="text-white font-sanbold font-bold text-xl">
                    {hotel[h].company_name}
                  </h1>
                  <p className="text-white invisible lg:visible font-sanRegular">
                    {hotel[h].description}
                  </p>
                  <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    value={hotel[h].rate}
                    size={24}
                    activeColor="#ffd700"
                  />
                  <button className="text-primary border-2 border-primary p-2 rounded-lg hover:bg-primary hover:text-white shadow-lg shadow-black">
                    Book Now
                  </button>
                </div>
              </div>
              {h < hotel.length - 1 ? (
                <a
                  onClick={() => addHotel()}
                  className="border-2 hover:border-white border-primary ml-2 mr-2 lg:mr-24 rounded-2xl"
                >
                  <FaAngleRight color="red" size={"4vw"} />
                </a>
              ) : (
                <a className="border-2 hover:border-white border-white ml-2 mr-2 lg:mr-24 rounded-2xl">
                  <FaAngleRight color="white" size={"4vw"} />
                </a>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <h1 className="flex justify-center text-xl lg:text-5xl space-x-5 font-extrabold text-primary font-sanbold mt-10">
        Discover
      </h1>
      <h1 className="text-center font-sanRegular text-lg">
        Chose the place you want to visit
      </h1>
      <div>
        {loaded ? (
          <Carousel responsive={responsive} infinite={true}>
            {data.map((item, index) => (
              <div
                key={index}
                className="w-[100vw] lg:w-[24vw] 2xl:w-[60vh] h-[auto]  pr-2 pl-2   mt-10 mb-10  lg:pl-10 "
              >
                {item.image.length > 0 ? (
                  <>
                    <div
                      className="w-[94vw] h-[40vh] lg:w-[24vw] h-96  lg:mr-0 mr-1 2xl:w-[60vh]   shadow-lg shadow-shallow rounded-xl"
                      style={{
                        backgroundImage: `url(${
                          "https://otc.visitoromia.org" + item.image[0].image
                        })`,
                        // backgroundImage: `url(${item.image[0].image})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                    <div className=" mr-10 p-2 lg:w-[24vw] w-[94vw] shadow-lg shadow-shallow pt-5 h-[150px]  overflow-hidden rounded-b-2xl ">
                      <h1 className="font-sanRegular text-black text-lg ">
                        {item.name}
                      </h1>
                      {/* <p className="text-xs max-h-10 overflow-hidden 2xl:text-sm">
                        {item.description}
                      </p> */}
                      <a
                        onClick={() => changeDest(item.slug)}
                        className="text-white hover:text-black hover:bg-white bg-primary px-2 rounded-sm shadow-sm font-sanRegular"
                      >
                        Explore
                      </a>
                    </div>
                  </>
                ) : null}
              </div>
            ))}
          </Carousel>
        ) : null}
      </div>
    </div>
  );
}
