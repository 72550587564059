import React, { useState, useEffect } from "react";
import { Modal } from "antd";

export default function Resource() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const getHeader = async () => {
    try {
      const response = await fetch("https://otc.visitoromia.org/api/culture/");
      const res = await response.json();
      setData(res);
      setLoaded(true);
    } catch (e) {}
  };
  const showModal = (image) => {
    setModalImage(image);
    setShow(true);
  };
  useEffect(() => {
    getHeader();
  }, []);
  return (
    <div>
      {loaded ? (
        <div>
          <div
            // style={{
            //   backgroundImage: `url(${one})`,
            //   backgroundSize: "cover",
            //   backgroundRepeat: "no-repeat",
            // }}
            className="w-screen h-[100vh] overflow-scroll scroll-smooth"
          >
            <div className="w-screen h-[auto] pb-20 ">
              {data.map((item, index) => (
                <>
                  <h1 className="text-4xl text-primary font-sanbold ml-24 pt-10 text-left first-latter:uppercase">
                    Gada System
                  </h1>
                  {item.content.map((item, index) => (
                    <>
                      <h1 className="text-xl pt-2 text-primary ml-24 font-sanbold mb-2">
                        {item.title}
                      </h1>
                      <p
                        className="text-sm text-black font-sanRegular backdrop-blur-sm bg-white/30 text-justify pt-2 ml-24 p-2 mr-24 first-line:uppercase first-line:tracking-widest
                        -mb-20
                        first-letter:text-7xl first-letter:font-bold first-letter:text-primary
                        first-letter:mr-3 first-letter:float-left"
                      >
                        {item.description}
                      </p>
                      <div className="flex flex-row m-20 mr-20">
                        {item.image.map((images, index) => (
                          <img
                            onClick={() =>
                              showModal(
                                "https://otc.visitoromia.org" + images.image
                              )
                            }
                            src={"https://otc.visitoromia.org" + images.image}
                            className="w-96 m-5 h-[40vh] shadow-lg shadow-black object-cover "
                          />
                        ))}
                      </div>
                    </>
                  ))}
                </>
              ))}
            </div>
            <Modal
              visible={show}
              onCancel={() => setShow(false)}
              footer={null}
              width="50%"
            >
              <img
                src={modalImage}
                className="w-[100vw] h-[100vh] shadow-lg shadow-black object-cover "
              />
            </Modal>
          </div>
        </div>
      ) : null}
    </div>
  );
}
