class MessageParser {
    constructor(actionProvider, state) {
        this.actionProvider = actionProvider;
        this.state = state;
    }

    parse(message) {
        if (message.length) {
            this.actionProvider.responsond(message)
        }
    }
}

export default MessageParser;