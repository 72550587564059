import React, { useState, useEffect } from "react";
import Container from "./Innercomponents/pd";
import ReactStars from "react-rating-stars-component";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";

import "react-animated-slider/build/horizontal.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export default function PopularDestination() {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [i, setI] = useState(0);
  const [j, setJ] = useState(0);
  const [cat, setCat] = useState([]);
  let history = useNavigate();

  const catList = async () => {
    try {
      const response = await fetch("https://otc.visitoromia.org/api/dest_list");
      const resp = await response.json();
      console.log(resp);
      setCat(resp);
    } catch (err) {
      console.log(err);
    }
  };

  async function apiCall(id) {
    try {
      const response = await fetch(
        `https://otc.visitoromia.org/api/pd/?search=${id}`
      );
      const res = await response.json();
      setData(res);
      if (res[0].destination_node) {
        setLoaded(true);
      } else {
        setLoaded(false);
      }
      history("/detail-pd", {
        state: { name: res },
      });
      setJ(0);
      console.log(res);
    } catch (e) {
      setLoaded(false);
      console.log(e);
      setJ(0);
    }
  }

  useEffect(() => {
    catList();
  }, []);
  return (
    <div className="w-screen mt-32 md:pl-10 md:pr-10  lg:pl-20 lg:pr-20 2xl:mt-32 h-[auto]  hover:cursor-pointer lg:mt-32  ml-2 mb-10">
      <h1 className="text-primary  2xl:text-5xl lg:text-4xl lg:mb-5 font-sanbold font-extrabold lg:ml-0 text-2xl -mt-28  ml-2">
        Popular destinations{" "}
      </h1>
      <h2 className="font-sanRegular text-sm md:text-lg md:max-w-lg lg:text-lg pb-4 text-gray-600 max-w-xs mx-2 2xl:ml-0 lg:max-w-lg 2xl:max-w-xl  lg:ml-0 2xl:text-2xl ">
        We have cataloged our most visited destinations for you.{" "}
      </h2>
      <Carousel
        responsive={responsive}
        className="-mt-0  h-[70vh] lg:h-hero lg:-mt-20 2xl:-mt-10 xl:-mt-20 lg:w-[88vw] w-[94vw]"
      >
        {cat.map((item, index) => (
          <div
            onClick={() => apiCall(item.id)}
            className="overflow-hidden  lg:w-[21vw] w-[100vw] md:w-[42vh] xl:w-[40vh] xl:h-[60vh] 2xl:w-[42vh] 2xl:h-[60vh] h-[80vh] lg:h-[50vh] shadow-xl hover:shadow-2xl hover:shadow-black rounded-xl "
          >
            <div
              key={index}
              style={{
                backgroundImage: `url(${
                  "https://otc.visitoromia.org" + item.image
                })`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundOrigin: "border-box",
              }}
              className="lg:w-[23vw]  xl:h-[70vh] md:w-[40vh] w-[100vw] h-[80vh] lg:h-[50vh] 2xl:h-[60vh] lg:ml-0 ml-0 shadow-xl hover:shadow-2xl shadow-black border-0  
               border-white  mb-10 "
            >
              <div className="lg:w-[97%] md:w-[40vh] h-[80vh] xl:w-[40vh] xl:h-[70vh] lg:h-[60vh] 2xl:w-[42vh] 2xl:h-[60vh] bg-[#ede7e724] flex flex-col justify-center  ">
                <a
                  key={index}
                  className=" p-0 font-sanbold text-lg text-white hover:text-otcdeep  hover:p-2 cursor-pointer lg:text-sm xl:text-xl text-center  "
                >
                  {item.name}
                </a>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      {/* <Container props={data} /> */}
      {/* {loaded ? (
        <div className="flex flex-col lg:flex-row lg:w-200 lg:h-auto lg:mt-5 -mt-14">
          <div className="p-width">
            <Slider
              previousButton={
                <button>
                  <FaAngleDoubleLeft size={60} color={"#da4453"} />
                </button>
              }
              nextButton={
                <button>
                  <FaAngleDoubleRight size={60} color={"#da4453"} />
                </button>
              }
            >
              {data[i].destination_node.image.map((image, index) => (
                <img
                  src={image.image}
                  className="lg:max-w-3xl lg:pt-5 max-w-xs -mt-14 mr-2 lg:-mt-1 transition ease-in-out delay-150 shadow-2xl shadow-emerald-50"
                />
              ))}
            </Slider>
          </div>
          <div className="lg:p-10 lg:mt-10">
            <h1 className="lg:font-description lg:text-xl pb-2 text-secondary">
              {data[i].destination_node.name}
            </h1>
            <p className="font-description text-xs max-w-lg">
              {data[i].destination_node.description}
            </p>
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={24}
              activeColor="#ffd700"
            />
            <button
              onClick={() => handleClick(data[i].destination_node)}
              className="bg-primary p-2 shadow-xl rounded-lg text-white mt-4 hover:bg-red-400"
            >
              Find out More about {data[i].destination_node.name}
            </button>
          </div>
        </div>
      ) : null} */}
    </div>
  );
}
