import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import "../../../App.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function Events() {
  const [data, setData] = useState([]);
  const [text, setText] = useState("");

  let history = useNavigate();
  const handleClick = (name, others) => {
    history("/packages", {
      state: { name: name, other: others },
    });
  };

  const giveRate = (own, value) => {
    var myHeaders = new Headers();
    var token = localStorage.getItem("access_token");
    var raw = JSON.stringify({ owner: own, rate: value });
    myHeaders.append("Authorization", "Bearer  " + token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch("https://otc.visitoromia.org/api/give_rate/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        apiCall();
      })
      .catch((error) => {
        console.log("rating error: ", error);
      });
  };
  const textGeter = async () => {
    try {
      const response = await fetch("https://otc.visitoromia.org/api/text/11");
      const res = await response.json();
      setText(res);
    } catch (error) {
      console.log(error);
    }
  };

  async function apiCall() {
    try {
      const response = await fetch("https://otc.visitoromia.org/api/package");
      const res = await response.json();
      setData(res);
    } catch (e) {}
  }
  useEffect(() => {
    apiCall();
    textGeter();
  }, []);

  return (
    <div className=" w-screen  -mt-20 lg:-mt-10 overflow-hidden  h-[auto]  lg:mb-32 mb-10">
      <h1 className="lg:text-primary font-sanbold lg:text-4xl text-2xl text-primary 2xl:text-5xl ml-24">
        Packages
      </h1>
      <p className="ml-24 font-sanRegular text-sm lg:text-lg text-ellipsis lg:max-w-5xl text-black  2xl:text-xl pb-0 lg:pb-0">
        Explore The Most Recent Packages Trending in Oromia, Ethiopia
      </p>
      <Carousel
        sliderClass="carouselContainer"
        itemClass="carouselContainerItem"
        dotListClass="dotesClass"
        responsive={responsive}
        showDots={true}
        autoPlay={false}
      >
        {data.map((item, index) => (
          <Zoom key={index}>
            <div
              className="  h-[60vh] 2xl:h-[600px]  shadow-xl hover:shadow-2xl shadow-black flex flex-col justify-end  mb-20 rounded-lg"
              style={{
                backgroundImage: `url(${item.image})`,
                backgroundSize: "cover",
              }}
            >
              <div className="p-2  backdrop-blur-sm bg-white/30 ">
                <h1 className="font-sanbold text-white text-lg 2xl:text-xl">
                  {item.title}
                </h1>
                <p className="text-xs text-white font-sanRegular">
                  Stay at {item.accomudation} for {item.duration}
                </p>
                <a
                  onClick={() => handleClick(item, data)}
                  className="text-primary cursor-pointer hover:text-white 2xl:text-2xl"
                >
                  Explore Package
                </a>
              </div>
            </div>
          </Zoom>
        ))}
      </Carousel>
    </div>
  );
}
