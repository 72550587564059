import React, { useState, useEffect } from "react";
import one from "../../assets/one.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import Zoom from "react-reveal/Zoom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function News() {
  const [events, setEvents] = useState([{}, {}, {}]);
  const [loaded, setLoaded] = useState(false);
  let history = useNavigate();

  const handleClick = (value) => {
    history("/news", {
      state: { name: value, others: events },
    });
  };
  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }
  const getHeader = async () => {
    try {
      const response = await fetch(`https://otc.visitoromia.org/api/event/`);
      const res = await response.json();
      console.log(res);
      setEvents(res);
      setLoaded(true);
    } catch (e) {}
  };
  useEffect(() => {
    getHeader();
  }, []);

  return (
    <div className="flex flex-1 flex-col w-screen h-auto 2xl:pt-28 lg:pt-24 h-[auto]  lg:h-[auto] mb-10 mt-80 md:mt-0">
      <br />
      <h1 className="text-primary text-2xl lg:text-4xl mt-0 mb-5 font-sanbold font-extrabold lg:ml-24 ml-4 2xl:text-5xl">
        Current Topics in Oromia
      </h1>
      <p className="font-sanRegular font-extralight 2xl:text-xl lg:text-lg pb-4  text-xs capitalize lg:ml-24 ml-4 text-gray-700 max-w-xs lg:max-w-lg 2xl:max-w-xl 2xl:pb-10">
        find out more about our Tourism hotspots and plan your trip{" "}
      </p>
      <div>
        {loaded ? (
          <Carousel responsive={responsive} autoPlay={false}>
            {events.map((item, index) => (
              <Zoom>
                <div className="ml-2 mb-20 mr-2 mt-0">
                  {get_url_extension(item.image) == "jpg" ? (
                    <div
                      className="flex flex-col w-[94vw]  lg:mr-20 justify-end overflow-x-hidden overflow-y-hidden h-[60vh] lg:h-[60vh]  lg:w-[24vw]  shadow-2xl lg:shadow-black rounded-xl"
                      style={{
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: "cover",
                        // height: 400,
                      }}
                    >
                      <div className="backdrop-blur-sm bg-white/30 ... p-2 h-20 lg:min-h-40 ">
                        <h1 className="lg:text-lg font-bold text-primary font-sanbold capitalize 2xl:text-2xl">
                          {item.title}
                        </h1>
                        <p className="text-black font-sanRegular text-xs font-extralight  2xl:text-lg">
                          {item.description}
                        </p>
                        <a
                          onClick={() => handleClick(item)}
                          className="text-white text-sm  hover:text-otcdeep cursor-pointer 2xl:text-lg"
                        >
                          Try Out
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <video
                        // id="myVideo"
                        style={{
                          width: "100vw",
                          height: "60vh",
                          borderRadius: 20,
                          marginBottom: 20,
                          backgroundColor: "black",
                        }}
                        className="shadow-lg"
                        autoPlay
                        controls
                      >
                        <source src={item.image} type="video/mp4" />
                        <source src={item.image} type="video/ogg" />
                      </video>
                      <div className="backdrop-blur-sm bg-white/30 ... p-2 h-20 lg:min-h-40 content rounded-lg mt-20  ">
                        <h1 className="lg:text-lg font-bold text-primary font-sanbold capitalize 2xl:text-2xl">
                          {item.title}
                        </h1>
                        {/* <p className="text-white font-sanRegular text-xs font-extralight  2xl:text-lg">
                          {item.description}
                        </p> */}
                        <a
                          onClick={() => handleClick(item)}
                          className="text-white text-sm  hover:text-otcdeep cursor-pointer 2xl:text-lg"
                        >
                          Try Out
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </Zoom>
            ))}
          </Carousel>
        ) : null}
      </div>
      {/* <button
        className="bg-black lg:bg-white border-gray border-2  text-xs lg:text-lg p-1 shadow-2xl mr-14 lg:ml-20 lg:mr-20  text-black hover:bg-white flex  justify-start hover:pl-20 duration-300 hover:text-otcredish 2xl:text-lg 2xl:p-3 lg:mt-10 lg:p-5"
        style={{ borderRadius: 20 }}
      >
        <FaAngleRight
          className="2xl:pt-2 lg:p-0 lg:pt-1"
          size={20}
          color={"red"}
        />{" "}
        Explore for more activities
      </button> */}
    </div>
  );
}
