import axios from 'axios'

class ActionProvider {
    constructor(
        createChatBotMessage,
        setStateFunc,
        createClientMessage,
        stateRef,
        createCustomMessage,
        ...rest
    ) {
        this.createChatBotMessage = createChatBotMessage;
        this.setState = setStateFunc;
        this.createClientMessage = createClientMessage;
        this.stateRef = stateRef;
        this.createCustomMessage = createCustomMessage;
    }

    responsond = async (message) => {
        var mess = ''
        var formData = new FormData()
        formData.append('question', message)

        var config = {
            method: 'post',
            url: 'https://otc.visitoromia.org/api/chatbot/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: formData
        };
        const response = await axios(config)
        const data = this.createChatBotMessage(response.data.response)
        this.setState((prevState) => ({
            ...prevState,
            messages: [...prevState.messages, data],
        }))

    }
}

export default ActionProvider;