import React, { useState, useEffect, useRef } from "react";
import one from "../../assets/route.jpeg";
import Carousel from "react-multi-carousel";
import { Parallax, ParallaxLayer, IParallax } from "@react-spring/parallax";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from "react-router-dom";
import Footer from "../Footer";
import styles from "./styles.module.css";

import { useSprings, animated, to as interpolate } from "@react-spring/web";
import { useDrag } from "react-use-gesture";
import { set } from "lodash";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const to = (i: number) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
});
const from = (_i: number) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r: number, s: number) =>
  `perspective(1500px) rotateX(30deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`;

function Deck({ data }) {
  var cards = data;
  console.log(cards);
  const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out
  const [props, api] = useSprings(cards.length, (i) => ({
    ...to(i),
    from: from(i),
  })); // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(
    ({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
      const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
      const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
      if (!down && trigger) gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      api.start((i) => {
        if (index !== i) return; // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index);
        const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
        const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
        const scale = down ? 1.1 : 1; // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });
      if (!down && gone.size === cards.length)
        setTimeout(() => {
          gone.clear();
          api.start((i) => to(i));
        }, 600);
    }
  );
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <>
      {props.map(({ x, y, rot, scale }, i) => (
        <animated.div
          className={styles.deck}
          key={i}
          style={{ x, y, marginBottom: "60%", width: 2000 }}
        >
          {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
          <animated.div
            {...bind(i)}
            style={{
              transform: interpolate([rot, scale], trans),
              backgroundImage: `url(${
                "https://otc.visitoromia.org" + cards[i].image
              })`,
              backgroundSize: "cover",
            }}
            className="w-[10vw]"
          >
            <p className=" bg-otctrans border-4 hover:bg-primary border-primary max-w-xl p-2 mt-80 absolute text-white text-sm  rounded-lg ">
              <p className="text-white text-lg">Explore{cards[i].name}</p>
              <p>{cards[i].description}</p>
            </p>
          </animated.div>
        </animated.div>
      ))}
    </>
  );
}

export default function Routes() {
  const [routes, setRoute] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [places, setPlaces] = useState([]);
  const [j, setJ] = useState(0);
  const [i, setI] = useState(0);
  const [active, setActive] = useState({});
  const location = useLocation();

  const getHeader = async (name) => {
    try {
      const response = await fetch(
        `https://otc.visitoromia.org/api/routes/?search=${name}`
      );
      const res = await response.json();
      console.log(res);
      console.log(location);

      setRoute(res[0]);
      setActive(res[0].route[0]);
      console.log(res[0]);
      setLoaded(true);
    } catch (e) {}
  };

  const onSelect = (value) => {
    // console.log(value.length);
    if (i < value.length) {
      setI(i + 1);
      setActive(value[i]);
    } else {
      setI(0);
      setActive(value[0]);
    }
    setJ(0);
  };
  useEffect(() => {
    getHeader(location.state.name.name);
  }, []);
  return (
    <div className="-mb-20">
      {loaded ? (
        <>
          <div
            className="w-screen flex flex-col justify-end rounded-b-[400px] "
            style={{
              backgroundImage: `url(${one})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              resizeMode: "stretch",
              backgroundRepeat: "no-repeat",
              minWidth: "100%",
              minHeight: "70vh",
            }}
          >
            <h1 className=" text-2xl lg:text-7xl text-left text-primary font-sanbold pl-20 bg-otctrans backdrop-blur-sm pb-20">
              {routes.name}
              <h1 className="text-3xl text-primary pb-2 text-center">
                Need To Know
              </h1>
            </h1>
          </div>
          <div className="lg:pt-0 lg:pl-24 lg:pr-20 pl-2 pr-2 ">
            <p
              className="text-xs lg:text-sm font-sanRegular  text-justify py-4  first-line:uppercase first-line:tracking-widest
                  first-letter:text-7xl first-letter:font-bold first-letter:text-primary
                  first-letter:mr-3 first-letter:float-left"
            >
              {routes.d1}
            </p>
            <p className="text-xs lg:text-sm font-sanRegular text-justify py-4 ">
              {routes.d2}
            </p>
            <p className="text-xs lg:text-sm font-sanRegular text-justify py-4 ">
              {routes.d2}
            </p>
          </div>
          <div className="pl-4 ">
            <h1 className="text-xl lg:text-4xl font-sanbold text-primary lg:text-primary lg:ml-20 lg:mb-4 -mb-4 text-left lg:text-left pl-0">
              Routes
            </h1>
            <div className="mt-10 mr-0 ml-0 h-[auto]">
              <div className="flex flex-row lg:flex-rowlg:justify-center justify-between">
                <h1 className="text-sm lg:text-2xl lg:pb-4 text-otcblack font-sanRegular lg:ml-20 lg:mb-10 text-left pl-0 lg:text-center">
                  {active.name}
                </h1>
                <a
                  onClick={() => onSelect(routes.route)}
                  className="p-2 ml-20 lg:mr-24 lg:ml-10 w-44 rounded-xl hover:bg-primary  h-[40px] text-black hover:text-white border-2 border-primar"
                >
                  Next Route
                </a>
              </div>

              <div className="w-screen h-[auto] lg:mb-[20vh] mb-[20vh]  lg:h-[70vh] lg:pl-20 flex flex-col lg:flex-row lg:pr-24">
                <img
                  src={"https://otc.visitoromia.org" + active.elements[j].image}
                  className="min-w-[50vw] h-[60vh] rounded-xl shadow-lg shadow-black"
                />
                <div className="lg:ml-10 ml-2 mr-2 lg:mt-10">
                  <h1 className="text-xl text-primary font-sanbold capitalize">
                    {active.elements[j].name}
                  </h1>
                  <p className="text-sm font-sanRegular max-h-[30vh] overflow-scroll scroll-smooth">
                    {active.elements[j].description}
                  </p>
                  <div>
                    {j > 0 ? (
                      <button
                        className="text-primary border-primary border-2 px-2 mr-2 hover:bg-primary hover:text-white rounded-lg"
                        onClick={() => setJ(j - 1)}
                      >
                        Back
                      </button>
                    ) : null}
                    {j < active.elements.length - 1 ? (
                      <button
                        className="text-primary border-primary border-2 px-2 mr-2 hover:bg-primary hover:text-white rounded-lg"
                        onClick={() => setJ(j + 1)}
                      >
                        Next
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
