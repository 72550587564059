import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import three from "../../assets/bg.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import one from "../../assets/hotel.jpg";
import GoogleMapReact from "google-map-react";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import ReactStars from "react-rating-stars-component";
// import Sticky from "react-sticky-el";

import { Carousel as Carousels } from "antd";

const responsives = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Marker = ({ text }) => (
  <div className="hover:bg-primary w-[40px] text-2xl h-[50px] rounded-lg text-primary hover:text-white p-4">
    {text}
  </div>
);
export default function PDetail() {
  const [loaded, setLoaded] = useState(false);
  const [main, setMain] = useState([]);
  const [activity, setActivity] = useState([]);
  const [vedio, setVedio] = useState([]);
  const [packages, setPackage] = useState([]);
  var [h, setH] = useState(0);
  const [hotel, setHotels] = useState([]);
  const [i, setI] = useState(0);
  var [a, setA] = useState(0);
  var [b, setB] = useState(0);
  const [data, setData] = useState([{}, {}, {}, {}]);
  const history = useNavigate();

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };
  const addHotel = () => {
    setH(h + 1);
  };

  const subHotel = () => {
    setH(h - 1);
  };
  const handleClick = (slug) => {
    history("/destinations", {
      state: { slug },
    });
  };

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };
  const center = {
    lat: 59.95,
    lng: 30.33,
  };
  const location = useLocation();
  console.log(location.state.name[i].destination_node.packages);
  const nextItem = (current) => {
    setI(current);
    setA(0);
    setB(0);
  };

  const setMainData = (value) => {
    setMain(value);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    setMain(location.state.name);
    setHotels(location.state.name[i].destination_node.hotels);
    setActivity(location.state.name[i].destination_node.activities);
    setPackage(location.state.name[i].destination_node.packages);
    setData(location.state.name[i].destination_node.others);
    setLoaded(true);
  }, []);

  return (
    <div className="-mb-10 flex flex-col">
      {loaded ? (
        <Carousels
          dotPosition="bottom"
          afterChange={(current) => nextItem(current)}
          effect="fade"
          className="lg:w-screen lg:h-hero w-screen"
        >
          {main.map((item, index) => (
            <div key={index}>
              <div
                className="w-screen lg:h-hero h-[500px] flex flex-col justify-end"
                style={{
                  zIndex: 1,
                  backgroundImage: `url(${item.destination_node.image[0].image})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  resizeMode: "stretch",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="backdrop-blur-sm bg-black/30 pb-10 pt-2">
                  <h1 className="text-primary text-3xl font-sanbold ml-2 lg:ml-24 lg:-mb-0 -mb-[25vh]">
                    {item.destination_node.name}
                  </h1>
                  <p className="text-white ml-2 lg:ml-24 mr-24 font-sanRegular lg:visible invisible">
                    {item.destination_node.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Carousels>
      ) : null}
      <div className="flex flex-col">
        <div className="flex flex-row">
          <div className=" lg:pl-24 lg:pr-24 lg:pt-10  p-3 ">
            <div className="flex flex-col ">
              {loaded ? (
                <div className="object-fill lg:mr-10 w-screen lg:w-[60vw]">
                  <h1 className="lg:text-lg text-primary pt-0 font-sanbold ">
                    {main[i].destination_node.catagory.name}
                  </h1>
                  <h1 className="lg:text-2xl text-xl 2xl:text-3xl font-sanbold">
                    {main[i].destination_node.name}
                  </h1>
                  <p
                    className="text-sm text-justify w-[50vw]  font-sanRegular first-line:tracking-widest
                       first-letter:font-bold first-letter:text-primary
                       first-letter:float-left"
                  >
                    {main[i].destination_node.description}
                  </p>
                  <img
                    src={main[i].destination_node.image[b].image}
                    className="object-cover lg:w-[50vw] 2xl:w-[100vh] mt-0 rounded-lg min-h-[50vh] max-h-[60vh]  shadow-xl shadow-black"
                  />

                  <div>
                    {b > 0 ? (
                      <button
                        onClick={() => setB(b - 1)}
                        className="bg-primary text-white p-2 my-1 mt-5 rounded-sm hover:bg-white hover:text-black hover:border-2  hover:border-primary hover:p-[6px] transition-colors duration-[1s] mr-2  font-sanRegular"
                      >
                        Back
                      </button>
                    ) : null}
                    {b < main[i].destination_node.image.length - 1 ? (
                      <button
                        onClick={() => setB(b + 1)}
                        className="bg-primary text-white p-2 my-1 mt-5 rounded-sm hover:bg-white hover:text-black hover:border-2  hover:border-primary hover:p-[6px] transition-colors duration-[1s]  font-sanRegular"
                      >
                        Next
                      </button>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {loaded ? (
                <>
                  {activity.length > 0 ? (
                    <div className="w-[93vw] lg:w-[60vw] mt-10">
                      <h1 className="text-lg text-primary font-sanbold">
                        Activity
                      </h1>
                      <h1 className="lg:text-2xl text-xl font-sanbold">
                        {/* {console.log("here i am ", activity)} */}
                        {activity[a].title}
                      </h1>
                      <p
                        className=" w-[93vw] lg:w-[50vw]  text-sm  2xl:max-w-3xl 2xl:text-sm text-justify  font-sanRegular first-line:tracking-widest
                       first-letter:font-bold first-letter:text-primary
                      first-letter:float-left"
                      >
                        {activity[a].description}
                      </p>
                      <img
                        src={"https://otc.visitoromia.org" + activity[a].image}
                        className="object-cover  lg:w-[50vw] min-h-[50vh] max-h-[60vh]  h-60 shadow-xl shadow-black border-4  transition-opacity rounded-lg"
                      />

                      {a < activity.length - 1 ? (
                        <button
                          onClick={() => setA(a + 1)}
                          className="bg-primary text-white p-2 my-1 mt-5 rounded-sm hover:bg-white hover:text-black hover:border-2  hover:border-primary hover:p-[6px] transition-colors duration-[1s]  font-sanRegular mr-2"
                        >
                          Next
                        </button>
                      ) : null}
                      {a > 0 ? (
                        <button
                          onClick={() => setA(a - 1)}
                          className="bg-primary text-white p-2 my-1 mt-5 rounded-sm hover:bg-white hover:text-black hover:border-2  hover:border-primary hover:p-[6px] transition-colors duration-[1s]  font-sanRegular"
                        >
                          Back
                        </button>
                      ) : null}
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>

          <div className=" w-[20vw] h-[900px] mt-28 invisible lg:visible -ml-[2vw] top-0 sticky">
            <div className="h-[45vh] -ml-52">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBJliyWS8E2mzOFmKpKJgJT2iUPX2m6G9M",
                }}
                className="border-2 border-primary rounded-2xl"
                defaultCenter={{ lat: main.Latitude, lng: main.Longitude }}
                defaultZoom={6}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
              >
                <Marker
                  lat={main.Latitude}
                  lng={main.Longitude}
                  text={main.name}
                />
              </GoogleMapReact>
            </div>
            <div className="h-[auto] w-[30vw] -ml-52 border-l-2 border-[0px] border-primary">
              <ul>
                {data.map((item, index) => (
                  <li className="text-lg p-2 uppercase hover:bg-primary hover:text-white rounded-md cursor-pointer transition-transform font-sanbold ">
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div
          className="w-screen h-imagebg bg-black mb-52 mt-10"
          style={{
            backgroundImage: `url(${three})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="bg-otctrans h-imagebg lg:pl-20 lg:pr-20 ">
            <h1 className="text-white font-sanbold shadow-2xl pt-10 text-4xl pb-5 2xl:text-5xl ml-2 lg:ml-0 ">
              Packages
            </h1>
            <p className=" text-white text-sanbold 2xl:text-lg pb-2 ml-2 lg:ml-0  ">
              Oromia has multiple Package Providers with ranging activities
            </p>
            {loaded ? (
              <div>
                {packages.length > 0 ? (
                  <Carousel responsive={responsive}>
                    {packages.map((item, index) => (
                      <div
                        className="mb-20  w-[94vw] h-[400px]  lg:h-threeImage lg:w-[29vw]  bg-whiteshadow-xl shadow-black flex flex-col justify-end rounded-lg"
                        style={{
                          backgroundImage: `url(${
                            "https://otc.visitoromia.org/" + item.image
                          })`,
                          backgroundSize: "cover",
                        }}
                      >
                        <div className=" p-2 hover:h-[50vh] transition duration-0 delay-500 ease-in  rounded-lg backdrop-blur-sm bg-otctrans/30">
                          <div className="myDIV">
                            <h1 className="text-white mb-2   hover:text-primary hover:decoration-white lg:text-xl font-sanbold">
                              {item.title}
                            </h1>
                            {/* <p className="text-xs">{item.description}</p> */}
                            <h1 className="text-lg text-white 2xl:text-lg font-sanRegular">
                              {item.duration} package with {item.accomudation}{" "}
                              accomudation and {item.transportation}
                            </h1>
                            <a
                              href={item.link}
                              className="text-primary mt-4 hover:text-white lg:text-lg hover:hidden transition-opacity cursor-pointer py-10 duration-500"
                              target={"_blank"}
                            >
                              More
                            </a>
                          </div>
                          <p className="hide">{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div>
                    <h1 className="text-primary text-2xl">
                      Package Not available
                    </h1>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
        {hotel.length > 0 ? (
          <div className="w-screen">
            <h1 className="flex justify-center text-xl  lg:text-5xl space-x-5 font-extrabold text-primary -mt-10 font-sanbold">
              Accommodations
            </h1>
            <h1 className="text-center font-sanRegular text-xl">
              Pick a place to stay
            </h1>
            <div
              className="w-[100vw]  bg-black h-hero "
              style={{
                backgroundImage: `url(${one})`,
                backgroundSize: "cover",
              }}
            >
              <div className="bg-otctrans w-screen flex flex-row justify-center items-center  h-hero">
                {h > 0 ? (
                  <a
                    onClick={() => subHotel()}
                    className="border-2 border-primary mr-2 hover:border-white ml-2 lg:ml-24 rounded-2xl"
                  >
                    <FaAngleLeft color="red" size={"4vw"} />
                  </a>
                ) : (
                  <a className="border-2 border-white mr-2 hover:border-white ml-2 lg:ml-24 rounded-2xl ">
                    <FaAngleLeft color="white" size={"4vw"} />
                  </a>
                )}
                <div
                  className="bg-primary lg:w-[120vh w-[100vw] mt-10 h-less  shadow-2xl shadow-black  flex flex-row justify-end rounded-lg"
                  style={{
                    backgroundImage: `url(${
                      "https://otc.visitoromia.org" + hotel[h].company_logo
                    })`,
                    backgroundSize: "cover",
                  }}
                >
                  <div className="hover:w-[50%] lg:p-20 p-10 w-[100%] transition-all ease-in-out duration-150 backdrop-blur-sm bg-white/30 rounded-lg">
                    <h1 className="text-white font-sanbold font-bold text-xl">
                      {hotel[h].company_name}
                    </h1>
                    <p className="text-white invisible lg:visible font-sanRegular">
                      {hotel[h].description}
                    </p>
                    <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      value={hotel[h].rate}
                      size={24}
                      activeColor="#ffd700"
                    />
                    <button className="text-primary border-2 border-primary p-2 rounded-lg hover:bg-primary hover:text-white shadow-lg shadow-black">
                      Book Now
                    </button>
                  </div>
                </div>
                {h < hotel.length - 1 ? (
                  <a
                    onClick={() => addHotel()}
                    className="border-2 hover:border-white border-primary ml-2 mr-2 lg:mr-24 rounded-2xl"
                  >
                    <FaAngleRight color="red" size={"4vw"} />
                  </a>
                ) : (
                  <a className="border-2 hover:border-white border-white ml-2 mr-2 lg:mr-24 rounded-2xl">
                    <FaAngleRight color="white" size={"4vw"} />
                  </a>
                )}
              </div>
            </div>
          </div>
        ) : null}

        <h1 className="flex justify-center text-xl lg:text-5xl space-x-5 font-extrabold text-primary font-sanbold -mt-5">
          Discover
        </h1>
        <h1 className="text-center font-sanRegular text-lg">
          Chose the place you want to visit
        </h1>
        <div className="mb-20">
          {loaded ? (
            <Carousel responsive={responsives} infinite={true}>
              {data.map((item, index) => (
                <div
                  key={index}
                  className="w-[100vw] lg:w-[24vw] 2xl:w-[60vh] h-[auto]  pr-2 pl-2   mt-10 mb-10  lg:pl-10 "
                >
                  {item.image.length > 0 ? (
                    <>
                      <div
                        className="w-[94vw] h-[40vh] lg:w-[24vw] h-96  lg:mr-0 mr-1 2xl:w-[60vh]   shadow-lg shadow-shallow rounded-xl"
                        style={{
                          backgroundImage: `url(${
                            "https://otc.visitoromia.org" + item.image[0].image
                          })`,
                          // backgroundImage: `url(${item.image[0].image})`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className=" mr-10 p-2 lg:w-[24vw] w-[94vw] shadow-lg shadow-shallow pt-5 h-[150px]  overflow-hidden rounded-b-2xl ">
                        <h1 className="font-sanRegular text-black text-lg ">
                          {item.name}
                        </h1>
                        {/* <p className="text-xs max-h-10 overflow-hidden 2xl:text-sm">
                        {item.description}
                      </p> */}
                        <a
                          onClick={() => handleClick(item.slug)}
                          className="text-white hover:text-black hover:bg-white bg-primary px-2 rounded-sm shadow-sm font-sanRegular"
                        >
                          Explore
                        </a>
                      </div>
                    </>
                  ) : null}
                </div>
              ))}
            </Carousel>
          ) : null}
        </div>
      </div>
    </div>
  );
}
