import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toInteger } from "lodash";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function TourGuid() {
  const [guid, setGuide] = useState([{}, {}, {}, {}]);
  const [hotels, setHotels] = useState([]);
  const [guidBio, setGuideBio] = useState({});
  const [loaded, setLoaded] = useState(false);
  const ratingChanged = () => {};
  const location = useLocation();

  const setBioGuider = (value) => {
    setGuideBio(value);
  };
  const apiGetter = async (value) => {
    try {
      const response = await fetch(
        `https://otc.visitoromia.org/api/hotel_list/?search=${value}`
      );
      const response1 = await fetch(
        "https://otc.visitoromia.org/api/travle_guide/"
      );
      const res1 = await response1.json();
      const res = await response.json();
      setGuide(res1);
      setHotels(res);
      setLoaded(true);
    } catch (e) {
      setLoaded(true);
    }
  };

  useEffect(() => {
    apiGetter("");
  }, []);

  if (location.state.name.name == "Tour Guides and Operators") {
    return (
      <div className="w-screen h-[auto] mb-2">
        <div className="bg-white  flex flex-col-reverse lg:flex-row justify-between border-b-2 border-green-900 mb-10 mx-24">
          <div>
            <h1 className="lg:text-2xl text-xl text-primary underline">
              Tour Guides and Operators
            </h1>
            <p className="capitalize lg:text-lg text-primary">
              {location.state.name.description}
            </p>
          </div>
          <img
            src={location.state.name.image}
            className="w-[60vw] h-[20vh] object-cover rounded-t-lg"
          />
        </div>
        {guidBio.images ? (
          <>
            <h1 className="mx-24 text-primary text-sanbold text-lg">
              {guidBio.name}
            </h1>
            <div className="flex flex-row mx-24 justify-between">
              {guidBio.images.map((item, index) => (
                <div
                  key={index}
                  className="rounded-lg shadow-lg shadow-black w-[27vw] h-[40vh] delay-[0.3s] object-fill flex flex-col justify-end align-baseline hover:w-[100vw] hover:h-[90vh] transition-all duration-[1s] hover:z-0"
                  style={{
                    backgroundImage: `url(${
                      "https://otc.visitoromia.org" + item.files
                    })`,
                  }}
                >
                  <p className="p-2 text-white font-sanbold">{item.about}</p>
                </div>
              ))}
            </div>
          </>
        ) : null}
        <div className="ml-2 lg:ml-24 lg:mr-24 lg:mt-20">
          <div>
            {guid.map((item, index) => (
              <div
                key={index}
                className="w-[100vw] h-[auto] lg:h-[50vh] mb-7 flex flex-col lg:flex-row lg:pr-32 pr-4 mt-6 "
              >
                <img
                  src={"https://otc.visitoromia.org" + item.logo}
                  className="rounded-lg shadow-lg shadow-black"
                />
                <div className="lg:p-10">
                  <h1 className="font-sanbold text-lg">{item.name}</h1>
                  <h2 className="font-sanRegular text-xs lg:text-sm ">
                    {item.description}
                  </h2>
                  <div className="flex flex-row justify-between w-[20vw]">
                    <a className="text-primary hover:text-black font-sanbold">
                      {item.email}
                    </a>
                    <a className="text-primary hover:text-black font-sanbold">
                      {item.web}
                    </a>
                    <a className="text-primary hover:text-black font-sanbold">
                      {item.phone}
                    </a>
                  </div>
                  <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={24}
                    activeColor="#ffd700"
                  />

                  <button
                    onClick={() => setBioGuider(item)}
                    className="p-2 hover:text-white hover:bg-primary bg-white border-2 rounded-lg border-primary "
                  >
                    Explore
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else if (location.state.name.name == "Hotels") {
    return (
      <div className="w-screen h-[auto] mb-0">
        <div className="bg-white  flex flex-col-reverse lg:flex-row justify-between border-b-2 border-green-900 mb-10 mx-24 mt-10">
          <div>
            <h1 className="text-2xl text-primary underline font-sanbold">
              Accommodation
            </h1>
            <p className="capitalize text-lg text-primary font-sanbold">
              {location.state.name.description}
            </p>
            <div>
              <a
                onClick={() => apiGetter("Hotels")}
                className="mr-4 p-2 text-primary border-[1px] rounded-lg hover:text-black"
              >
                Hotels
              </a>
              <a
                onClick={() => apiGetter("Restaurants")}
                className="mr-4 p-2 text-primary  border-[1px] rounded-lg hover:text-black"
              >
                Restaurants
              </a>
              <a
                onClick={() => apiGetter("HoteBarsls")}
                className="mr-4 p-2 text-primary  border-[1px] rounded-lg hover:text-black"
              >
                Bars
              </a>
              <a
                onClick={() => apiGetter(" Night Club")}
                className="mr-4 p-2 text-primary  border-[1px] rounded-lg hover:text-black"
              >
                Night Club
              </a>
              <a
                onClick={() => apiGetter("Lounges")}
                className="mr-4 p-2 text-primary  border-[1px] rounded-lg hover:text-black"
              >
                Lounges
              </a>
              <a
                onClick={() => apiGetter("Cultural House")}
                className="mr-4 p-2 text-primary  border-[1px] rounded-lg hover:text-black"
              >
                Cultural House
              </a>
              <a
                onClick={() => apiGetter("Fast Food")}
                className="mr-4 p-2 text-primary  border-[1px] rounded-lg hover:text-black"
              >
                Fast Food
              </a>
            </div>
          </div>
          <img
            src={location.state.name.image}
            className="w-[40vh] h-[20vh] object-cover rounded-t-lg"
          />
        </div>
        <p className="pl-24 pr-24 mt-10 text-sm max-w-5xl font-sanRegular">
          Anim veniam nostrud et voluptate deserunt quis elit duis Lorem
          incididunt adipisicing sunt ad sint. Laboris tempor aliquip magna
          veniam ipsum do ea sint. Eu mollit quis officia dolor elit sunt non
          aute aute elit adipisicing minim adipisicing minim. Laborum nostrud
          elit fugiat anim est officia sint nostrud magna magna cupidatat
          cupidatat.
        </p>
        <Carousel responsive={responsive} className="ml-24 mt-10 mr-24 mb-24">
          {hotels.map((item, index) => (
            <div
              style={{
                backgroundImage: `url(${item.company_logo})`,
                backgroundSize: "cover",
              }}
              className="w-[28vw] h-[70vh] flex flex-col justify-end rounded-lg mb-10"
            >
              <div className="bg-otctrans p-5 rounded-lg shadow-lg shadow-black">
                <h1 className="text-primary text-lg font-sanbold ">
                  {item.company_name}
                </h1>
                <p className="text-white font-sanRegular ">
                  {item.description}
                </p>
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={24}
                  value={toInteger(item.rate)}
                  activeColor="#ffd700"
                />
                <br />
                <a
                  href={item.link}
                  className="bg-primary p-2 rounded-lg text-white hover:text-black hover:bg-white "
                >
                  Read More
                </a>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
}
