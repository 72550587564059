import React, { useState } from "react";
import { HashRouter as BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Components/Home";
import NavDetail from "../Components/lowerPage/NavDetail";
import NavBar from "../Components/Innercomponents/NavBar";
import HeroDetail from "../Components/lowerPage/HeroDetail";
import DestinationDetail from "../Components/lowerPage/DestinationDetail";
import DestinationRoutes from "../Components/lowerPage/DestinationRoutes";
import PDetail from "../Components/lowerPage/PDetail";
import ActivityDetail from "../Components/lowerPage/ActivityDetail";
import Footer from "../Components/Footer";
import Resource from "../Components/lowerPage/Resource";
import config from "../Chatbot/chatbotConfig";
import MessageParser from "../Chatbot/MessageParser";
import ActionProvider from "../Chatbot/ActionProvider";
import Chatbot from "react-chatbot-kit";
import Culture from "../Components/lowerPage/culture";
import TourGuid from "../Components/lowerPage/tourGuid";
import Photo from "../Components/lowerPage/Photo";
import Vedio from "../Components/lowerPage/Vedio";
import Information from "../Components/Innercomponents/Infor";
import About from "../Components/Innercomponents/about";
import Contact from "../Components/Innercomponents/contact";
import Privacy from "../Components/Innercomponents/privacy";
import Terms from "../Components/Innercomponents/terms";
import Help from "../Components/Innercomponents/help";
import PackageDetail from "../Components/lowerPage/PackageDetail";
import MeetLocations from "../Components/MeetLocations";
import "react-chatbot-kit/build/main.css";
import Zoom from "react-reveal/Zoom";

import "../chatbot.css";
import News from "../Components/lowerPage/News";

export default function OtcRoutes() {
  const [chatbot, setChatbot] = useState(false);
  const toggleChatbot = () => {
    setChatbot(!chatbot);
  };
  return (
    <div className="p-0 m-0 w-[100%]">
      <BrowserRouter>
        <NavBar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/detail" element={<NavDetail />} />
          <Route path="/destinations" element={<HeroDetail />} />
          <Route path="/detail-destination" element={<DestinationDetail />} />
          <Route path="/detail-pd" element={<PDetail />} />
          <Route path="/routes" element={<DestinationRoutes />} />
          <Route path="/activitys" element={<ActivityDetail />} />
          <Route path="/resources" element={<Resource />} />
          <Route path="/culture" element={<Culture />} />
          <Route path="/info" element={<Information />} />
          <Route path="/guid" element={<TourGuid />} />
          <Route path="/news" element={<News />} />
          <Route path="/vedio" element={<Vedio />} />
          <Route path="/gallary" element={<Photo />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/help" element={<Help />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/packages" element={<PackageDetail />} />
          <Route path="/meetLocation" element={<MeetLocations />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      {/* </Zoom> */}
      {chatbot ? (
        <div className="chatPortal">
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
          <a onClick={() => toggleChatbot()} className="container-cancle"></a>
        </div>
      ) : (
        <a onClick={() => toggleChatbot()} className="container"></a>
      )}
    </div>
  );
}
