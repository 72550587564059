import React, { useState, useEffect } from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

export default function MeetLocations() {
  const [faq, setFaq] = useState([]);
  const [error, setError] = useState(false);

  const apiGetter = async () => {
    try {
      const response = await fetch("https://otc.visitoromia.org/api/faq");
      const res = await response.json();
      setFaq(res);
    } catch (e) {
      setError(true);
    }
  };

  function callback(key) {
    console.log(key);
  }

  useEffect(() => {
    apiGetter();
  }, []);

  return (
    <>
      {!error ? (
        <div className="w-[80vw] flex justify-center flex-col pl-[6vw] mb-[5vw]">
          <Collapse onChange={callback} className="w-[88vw]">
            {faq.map((data, index) => (
              <Panel
                header={data.owner.name}
                key={index}
                style={{ backgroundColor: "#00000000" }}
              >
                <p style={{ backgroundColor: "#00000000" }}>{data.content}</p>
              </Panel>
            ))}
          </Collapse>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
