import "./App.css";
import React from "react";
import OtcRoutes from "./otcRoute";
import "antd/dist/antd.css";

function App() {
  return <OtcRoutes />;
}

export default App;
