import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { Carousel } from "antd";

export default function Hero() {
  const [slide, setSlide] = useState([]);
  const [fetcher, setFetcher] = useState(false);
  const history = useNavigate();
  const fetchSliders = async () => {
    try {
      var data = [];
      const response = await fetch("https://otc.visitoromia.org/api/slideui/");
      const res = await response.json();
      for (var i = 0; i < res.length; i++) {
        var id = res[i].id;
        var date = res[i].created_date;
        var description = res[i].description;
        var image = res[i].image.image[0];
        var link = res[i].link;
        var slug = res[i].slug;
        var destSlug = res[i].image.slug;
        var title = res[i].title;
        var obj = {
          id: id,
          date: date,
          description: description,
          image: image,
          link: link,
          slug: slug,
          destSlug: destSlug,
          title: title,
        };

        data.push(obj);
      }

      setSlide(data);
      setFetcher(true);
    } catch (e) {
      console.log(e);
    }
  };
  const redirection = (slug) => {
    history("/destinations", {
      state: { slug },
    });
  };
  useEffect(() => {
    fetchSliders();
  }, []);

  const styles = useSpring({
    loop: false,
    to: [
      { opacity: 1, color: "#ffaaee" },
      { opacity: 1, color: "white" },
    ],
    from: { opacity: 1, color: "red" },
  });

  return (
    <div className="lg:flex-1 lg:w-screen  min-w-fit border-b-4 border-background ">
      {fetcher ? (
        <Carousel
          effect="fade"
          className="lg:w-screen lg:h-hero w-screen"
          autoplay={true}
        >
          {slide.map((item, index) => (
            <div>
              <div
                className="w-screen lg:h-hero h-[60vh] flex flex-col "
                style={{
                  zIndex: 1,
                  backgroundImage: `url(${item.image.image})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  resizeMode: "stretch",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="h-hero ">
                  <animated.div
                    className="text-white lg:text-4xl 2xl:text-5xl text-2xl pt-[10vh]  lg:pt-30 mx-2 max-w-sm font-sanbold  lg:mx-20 border-b-primary border-b-2   "
                    style={styles}
                  >
                    Explore the Best of Oromia
                  </animated.div>
                  <div className="lg:py-4 lg:px-20 px-2">
                    <p className="text-white text-xs py-10 lg:text-sm 2xl:text-lg font-sanRegular lg:max-w-xl max-w-xs lg:visible">
                      {item.description}
                    </p>
                  </div>
                  <button
                    onClick={() => redirection(item.destSlug)}
                    className="btn btn--animation-from-right  backdrop-blur-sm bg-white/40 "
                  >
                    <span class="btn__text-static font-sanRegular ">
                      {" "}
                      Travel To {item.title}
                    </span>
                    <div class="btn__text-dynamic">
                      <span class="btn__text-dynamic-inner font-sanRegular">
                        {" "}
                        Travel To {item.title}
                      </span>
                    </div>
                  </button>

                  {/* <button
                    onClick={() => redirection(item.destSlug)}
                    className="border-primary border-2 -mt-12  ml-2 lg:ml-20 p-2 lg:p-4 text-white rounded-lg hover:bg-red-500 delay-150 duration-300 font-sanRegular lg:text-xl  text-xs backdrop-blur-sm bg-white/30 ..."
                  ></button> */}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      ) : null}
    </div>
  );
}
