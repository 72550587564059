import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../App.css";

import Zoom from "react-reveal/Zoom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function Blog() {
  const [blog, setBlog] = useState([]);
  const [hider, setHider] = useState("hide");
  const [readmore, setRead] = useState("p-2 text-primary hover:text-black");
  const blogGetter = async () => {
    try {
      const response = await fetch("https://otc.visitoromia.org/api/blog/");
      const res = await response.json();
      setBlog(res);
    } catch (e) {}
  };
  const hideUnhide = () => {
    setHider(
      "text-white text-xs p-2 lg:text-sm  text-transportation font-sanRegular max-w-[90vw] "
    );
    setRead("hide");
  };

  useEffect(() => {
    blogGetter();
  }, []);

  return (
    <div className="bg-otctrans w-screen lg:h-hero h-[50vh] lg:pl-24 lg:pr-24">
      <h1 className="lg:text-white text-white text-extrabold pb-2  pt-4 ml-0 mt-4 2xl:text-4xl text-2xl  font-sanbold">
        Quick Reads
      </h1>
      <p className="text-white max-w-xl lg:pl-0 lg:pb-2 lg:text-xl lg:max-w-4xl pl-2 font-sanRegular">
        Read it from Visitors and Bloggers that have enjoyed The Diverse Beauity
        of Oromia.
      </p>
      <div>
        <Carousel responsive={responsive} infinite arrows={false}>
          {blog.map((item, index) => (
            <Zoom>
              <div
                key={index}
                style={{
                  backgroundImage: `url(${
                    "https://otc.visitoromia.org" + item.image
                  })`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundOrigin: "border-box",
                }}
                className=" lg:w-[27vw] lg:pr-0 w-[94vw] ml-2 h-[75vh] lg:h-[70vh] xl:h-[70vh] lg:ml-0 shadow-xl hover:shadow-2xl lg:hover:shadow-black border-0 rounded-lg
               border-white flex flex-col justify-end mb-20 "
              >
                <div className=" pb-2 backdrop-blur-sm bg-white/30 ...">
                  <h1 className="pl-2 pt-2 text-white font-fontbold text-sm  lg:text-xl">
                    {item.title}
                  </h1>
                  <a className={readmore} onClick={() => hideUnhide()}>
                    Read More
                  </a>
                  <p className={hider}>{item.body}</p>
                </div>
              </div>
            </Zoom>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
