import React from "react";
import SimpleReactFooter from "simple-react-footer";
import {
  FaLinkedin,
  FaFacebook,
  FaTwitter,
  FaPinterest,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";

export default function Footer() {
  return (
    <div
      className="w-[100vw] lg:w-screen 2xl:w-screen bg-[black]"
      style={{
        zIndex: 9999,
        height: 300,
      }}
    >
      <div>
        <div className="mx-24 pt-10">
          <h1 className="text-primary lg:text-3xl">Visit Oromia</h1>
          <p className=" text-white text-[0px] lg:text-xl 2xl:text-2xl font-sanRegular max-w-[45vh] lg:max-w-[100%]">
            {" "}
            Oromia is the home to many endemic species of wild animals and
            birds. The Diversity and Beauty of the nature are at their peak.
          </p>
        </div>
        <div className="flex flex-row justify-center">
          <a
            href="#/about"
            className="text-white pr-2 lg:pr-10 hover:text-primary"
          >
            About
          </a>
          <a
            href="#/contact"
            className="text-white pr-2 lg:pr-10 hover:text-primary"
          >
            Contact
          </a>
          <a
            href="#/privacy"
            className="text-white pr-2 lg:pr-10 hover:text-primary"
          >
            Privacy
          </a>
          <a
            href="#/term"
            className="text-white pr-2 lg:pr-10 hover:text-primary"
          >
            Terms
          </a>
          <a
            href="#/help"
            className="text-white pr-2 lg:pr-10 hover:text-primary"
          >
            Help
          </a>
        </div>
        <div className="flex flex-row justify-center p-10 ml-4">
          <a href="https://facebook.com/visitoromia">
            <FaFacebook style={{ marginRight: 20 }} color={"white"} size={30} />
          </a>
          <a href="https://instagram.com/VisitOromia">
            <FaInstagram
              style={{ marginRight: 20 }}
              color={"white"}
              size={30}
            />
          </a>
          <a href="https://twitter.com/VisitOromia">
            <FaTwitter style={{ marginRight: 20 }} color={"white"} size={30} />
          </a>
          <a href="https://twitter.com/VisitOromia">
            <FaPinterest
              style={{ marginRight: 20 }}
              color={"white"}
              size={30}
            />
          </a>
          <a href="https://youtube.com/VisitOromia">
            <FaYoutube style={{ marginRight: 20 }} color={"white"} size={30} />
          </a>
          <a href="https://linkedin.com/visitoromia">
            <FaLinkedin style={{ marginRight: 20 }} color={"white"} size={30} />
          </a>
        </div>
        <div className="flex flex-row justify-center">
          <p className="text-white">
            Copyright ©{" "}
            <a href="#" className="text-primary hover:text-white">
              VisitOromia
            </a>
          </p>
        </div>
      </div>

      {/* <SimpleReactFooter
        description={
          <p className="text-[0px] lg:text-xl 2xl:text-2xl font-sanRegular max-w-[45vh] lg:max-w-[100%]">
            {" "}
            Oromia is the home to many endemic species of wild animals and
            birds. The Diversity and Beauty of the nature are at their peak.
          </p>
        }
        title={
          <p className="text-primary text-sm lg:text-[28px] 2xl:text-4xl  font-sanbold">
            Visit Oromia
          </p>
        }
        columns={columns}
        linkedin="visitoromia"
        facebook="visitoromia"
        twitter="VisitOromia"
        instagram="VisitOromia"
        youtube="visitoromia"
        pinterest="visit_oromia"
        copyright={
          <a href="#" className="text-primary hover:text-white">
            visitoromia
          </a>
        }
        iconColor="white"
        backgroundColor="black"
        fontColor="white"
        // copyrightColor="red"
      /> */}
    </div>
  );
}
