import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function News() {
  const [news, setNews] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [eventsall, setEvents] = useState([]);
  const location = useLocation();

  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }
  const setNewsNew = (value) => {
    setNews(value);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    setNews(location.state.name);
    var list = [];
    // setEvents(location.state.others);
    location.state.others.filter((state) => {
      if (state.id != location.state.name.id) {
        list.push(state);
      }
    });
    setEvents(list);
    setLoaded(true);
  }, []);

  return (
    <div>
      {loaded ? (
        <div className="flex flex-col">
          <div className="w-screen h-[auto] bg-white p-2 lg:p-24 flex flex-col-reverse lg:flex-row justify-between">
            <div className="backdrop-blur-sm bg-primary p-2 rounded-lg shadow-lg shadow-black mr-2">
              <h1 className="text-2xl text-white underline">{news.title}</h1>
              <p className="capitalize text-sm max-w-4xl text-white font-sanRegular">
                {news.description}
              </p>
            </div>
            {get_url_extension(news.image) == "jpg" ? (
              <img src={news.image} className="w-[60vw] rounded-lg" />
            ) : (
              <video
                // id="myVideo"
                style={{
                  width: "100vw",
                  height: "60vh",
                  borderRadius: 20,
                  marginBottom: 20,
                  backgroundColor: "black",
                }}
                className="shadow-lg"
                autoPlay
                controls
              >
                <source src={news.image} type="video/mp4" />
                <source src={news.image} type="video/ogg" />
              </video>
            )}
          </div>
          <div className="mb-10 flex flex-row  lg:ml-24  lg:mr-24">
            {news.images.map((item, index) => (
              <img
                src={"https://otc.visitoromia.org" + item.image}
                className="w-[27vw] -mt-10 rounded-xl shadow-lg shadow-black mr-10"
              />
            ))}
          </div>
          <h1 className="ml-24 font-sanbold text-4xl text-primary mt-0 sticky top-0">
            More Topics
          </h1>
          <div className="flex flex-col mt-10 ml-24 mb-10">
            {eventsall.map((item, index) => (
              <div className="flex flex-row mb-20">
                {get_url_extension(item.image) == "jpg" ? (
                  <img
                    src={item.image}
                    className="w-[50vw]  -mt-10 rounded-xl shadow-lg shadow-black mr-10 mb-20"
                  />
                ) : (
                  <video
                    // id="myVideo"
                    style={{
                      width: "50vw",
                      height: "60vh",
                      borderRadius: 20,
                      marginBottom: 20,
                      backgroundColor: "black",
                      marginRight: "2vw",
                    }}
                    className="shadow-lg"
                    autoPlay
                    controls
                  >
                    <source src={item.image} type="video/mp4" />
                    <source src={item.image} type="video/ogg" />
                  </video>
                )}
                <div className="w-[35vw]">
                  <h1 className="text-2xl text-primary font-sanbold">
                    {item.title}
                  </h1>

                  <p className="capitalize text-sm max-w-3xl text-otcblack font-sanRegular">
                    {item.description}
                  </p>
                  <div className="flex  flex-row mb-10 ">
                    {item.images.map((item, index) => (
                      <img
                        key={index}
                        src={"https://otc.visitoromia.org" + item.image}
                        className="w-[150px] mt-10 rounded-xl shadow-lg shadow-black mr-4"
                      />
                    ))}
                  </div>
                  <a
                    onClick={() => setNewsNew(item)}
                    className="bg-primary text-white hover:text-black hover:bg-white hover:border-2 hover:border-primary p-2 rounded-lg"
                  >
                    Explore
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
