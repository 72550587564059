import React, { useState, useEffect } from "react";
import one from "../../assets/bg.jpg";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

export default function About() {
  const [otcstaff, setOtcStaf] = useState([]);

  const apiGetter = async () => {
    try {
      const response = await fetch(
        "https://otc.visitoromia.org/api/contact_list/"
      );
      const res = await response.json();
      setOtcStaf(res);
    } catch (e) {}
  };
  useEffect(() => {
    apiGetter();
  }, []);
  return (
    <div>
      <div className="w-screen h-[auto] mb-2 bg-primary p-2 lg:pl-24 flex flex-row justify-between py-10 ">
        <div>
          <Zoom>
            <h1 className="text-2xl text-white underline">
              {" "}
              About Oromia Tourism Commision
            </h1>
            <p className="uppercase text-sm max-w-4xl text-white">
              <h1 className="text-lg text-white">
                Oromia is Ethiopians largest regional state with diverse
                demographic destinations
              </h1>
              <p className="text-xs max-w-[70vw]">
                Experiance Oromia's Diverse Beauity
              </p>
            </p>
          </Zoom>
        </div>
      </div>
      <div className="ml-2 mr-2 lg:ml-20 lg:mr-20 mt-10 h-[auto] mb-10">
        {" "}
        <Zoom>
          <p
            className="font-description first-line:uppercase first-line:tracking-widest
                      first-letter:text-7xl first-letter:font-bold first-letter:text-primary
                      first-letter:mr-3 first-letter:float-left pb-10"
          >
            The Oromia Tourism Commission is an organization answerable to the
            Office of the President of the Oromia National Regional State and is
            headquartered in Finfinnee on the 7th Floor of Noah Plaza on Africa
            Av. Founded to spearhead the promotion of tourism into Oromia, the
            commission serves as the premier marketing and information center
            for tourism into Oromia and works with stakeholders in the tourism
            sector to promote in-bound tourism as well as destinations and host
            communities across Oromia. Consisting of departments that span
            research, destination development, marketing, and liaison, the
            commission is geared towards growing in-bound tourism into Oromia
            while ensuring host communities are primary partners and
            beneficiaries of this growth. The commission partners with
            communities across Oromia to develop destinations and build the
            capacity of local youth to deliver services creating sustainable
            jobs in the process. The commission is staffed by an army of
            passionate young women and men who work day and night to get the
            word about Oromia and all its beautiful destinations out. These
            experts and professionals work in areas such as community outreach,
            tourism destination development and promotion, marketing and social
            media management as well as external and public relations, throwing
            their significant weights behind the mission of promoting Oromia as
            the next best destination.
          </p>
        </Zoom>
        <div>
          <h1 className="text-xl text-primary px-2">Mandate</h1>
        </div>
        <div className="px-2">
          <Zoom>
            <li>
              Transform Oromia tourism into a vibrant, profitable and
              sustainable industry;
            </li>
            <li>Market Oromia as top desirable tourist destination;</li>
            <li>
              Develop tourism destinations to fit the needs and requirements of
              tourists;
            </li>
            <li>
              Support a cooperative relationship between the private sector, the
              government and host communities with respect to Oromia tourism;
            </li>
            <li>
              Provide data/information on Oromia tourism to the private sector,
              the government and other bodies; and
            </li>
            <li>Generate resources, establish and manage tourism fund.</li>
          </Zoom>
        </div>
        <div style={{ backgroundImage: `url(${one})` }} className="my-10">
          <h1 className="text-4xl text-primary py-10 px-2">Our Mission</h1>
        </div>
        <Zoom>
          <div className="px-3">
            <p>Over the coming five years (2021-2025),</p>
            <p>
              1.Grow annually tourism income, export revenues, for the economy
              by ten fold;{" "}
            </p>
            <p>
              2.Create 500,000 job/employment opportunities for the youth and
              women; and{" "}
            </p>
            <p>
              3.Contribute annually to 10-15 destinations host communities’
              income, livelihood improvement by two fold.
            </p>
            <h1 className="text-xl">Strategic Objectives</h1>
            <div>
              <li>
                Forge and sustain resourceful multi-stakeholder collaboration
                and partnership including with other regions;
              </li>
              <li> Focus on markets of highest return on investment;</li>
              <li>
                {" "}
                Tourism products and services value innovation with
                participation of tourists;{" "}
              </li>
              <li>
                {" "}
                Create and lead industry in brand relevancy and consistency;
              </li>
              <li> Create high-yield domestic customers;</li>
              <li>
                Enhance tourism’s contribution to peace; and Respond to changing
                market dynamics.
              </li>
            </div>
          </div>
        </Zoom>
        <div className="mt-10 py-2" style={{ backgroundImage: `url(${one})` }}>
          <p className="text-lg text-white p-2">DEDICATED TEAM LEADERS</p>
          <h1 className="text-4xl text-primary px-2">OTC Team</h1>
          <p className="p-2 text-white max-w-5xl">
            Oromia Tourism Commission Has Currently 5 directorates. These are
            Finance and Administration Directorate, Research and Analytics
            Directorate, Marketing and Promotion Directorate, Resource
            Mobilization and Tourism Fund, Destination Development Directorate.
          </p>
        </div>
        <div className="flex flex-row flex-wrap w-screen">
          {otcstaff.map((item, index) => (
            <Fade>
              <div className="bg-white shadow-md mr-28 mt-10 p-4 hover:shadow-xl hover:shadow-black">
                <img
                  src={"https://otc.visitoromia.org" + item.image}
                  className="w-[22vw]"
                />
                <h1 className="text-primary py-2">{item.name}</h1>
                <h2>{item.description}</h2>
                <a className="text-primary hover:text-black">{item.email}</a>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </div>
  );
}
