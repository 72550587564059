import React, { useState, useEffect } from "react";
import { Popover } from "antd";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import {
  FaAngleDown,
  FaTree,
  FaAccessibleIcon,
  FaAlignJustify,
} from "react-icons/fa";
import { Drawer, Button, Space, Radio } from "antd";

export default function NavBar() {
  const [header, setHeader] = useState([]);
  let history = useNavigate();

  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const showDrawer = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleClick = (value, name) => {
    console.log(value, name);
    if (name == "Destinations") {
      history("/routes", {
        state: { name: value },
      });
    } else if (name == "Culture") {
      history("/resources", {
        state: { name: name },
      });
    } else if (value.name == "Videos") {
      history("/vedio", {
        state: { name: value },
      });
    } else if (value.name == "Photos") {
      history("/gallary", {
        state: { name: value },
      });
    } else if (value.name == "Get VISA") {
      history("/info", {
        state: { name: value },
      });
    } else if (
      (value.name == "Latest COVID travel information") |
      (value.name == "FAQ")
    ) {
      history("/info", {
        state: { name: value },
      });
    } else if (value.name == "Fly directly to Finfine") {
      history("/info", {
        state: { name: value },
      });
    } else if (value.header == "Meet in Oromia") {
      history("/meetLocation", {
        state: { name: value },
      });
    } else if (
      (value.name == "Tour Guides and Operators") |
      (value.name == "Hotels")
    ) {
      history("/guid", {
        state: { name: value },
      });
    }
  };
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  const getHeader = async () => {
    try {
      const response = await fetch("https://otc.visitoromia.org/api/header");
      const res = await response.json();
      setHeader(res);
    } catch (e) {}
  };

  useEffect(() => {
    getHeader();
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <div
      className="lg:flex lg:flex-row flex-row -mb-6 lg:-mb-0 lg:mt-0 lg:ml-20 lg:bg-white flex  h-head lg:h-headMain overflow-x-hidden"
      style={{ zIndex: 1 }}
    >
      <a href="/" style={{ zIndex: 1 }}>
        <img
          src={require("../../assets/logo.png")}
          className="w-24 h-headImage lg:h-16 ml-5 lg:w-36 mt-2 lg:mt-0"
        />
      </a>
      <div className="visible lg:invisible ">
        {/* <Button type="primary" onClick={showDrawer}>
          Open
        </Button> */}
        <Drawer
          title={<h1 className="text-primary pt-4 text-2xl">Visit Oromia</h1>}
          placement={placement}
          width={"90vw"}
          onClose={onClose}
          visible={visible}
        >
          {header.map((item, index) => (
            <div className="overflow-hidden">
              <Popover
                content={
                  <div className="flex flex-col overflow-hidden ">
                    {item.dropdown.map((i, j) => (
                      <div className="">
                        <h1
                          onClick={() => handleClick(i, i.header)}
                          className="text-otcblack font-sanRegular lg:text-lg hover:text-white hover:bg-primary cursor-pointer p-2 rounded-sm"
                        >
                          {i.name}
                        </h1>
                      </div>
                    ))}
                  </div>
                }
              >
                <a className="flex flex-row text-headerFont hover:text-black lg:mx-0 lg:text-lg text-xl lg:p-2  lg:bg-#00732F-900 font-sanRegular">
                  {item.name} <FaAngleDown style={{ paddingTop: 10 }} />
                </a>
              </Popover>
            </div>
          ))}
        </Drawer>
      </div>

      <div className="flex flex-row lg:py-5 lg:visible invisible ">
        {header.map((item, index) => (
          <div className="overflow-hidden">
            <Popover
              content={
                <div className="flex flex-col overflow-hidden ">
                  {item.dropdown.map((i, j) => (
                    <div className="">
                      <h1
                        onClick={() => handleClick(i, i.header)}
                        className="text-otcblack font-sanRegular lg:text-lg hover:text-white hover:bg-primary cursor-pointer p-2 rounded-sm"
                      >
                        {i.name}
                      </h1>
                    </div>
                  ))}
                </div>
              }
            >
              <a className="flex flex-row text-headerFont hover:text-black lg:mx-5 lg:text-lg lg:p-2  lg:bg-#00732F-900 font-sanRegular">
                {item.name} <FaAngleDown style={{ paddingTop: 10 }} />
              </a>
            </Popover>
          </div>
        ))}
        <a className="ml-[8vw]" id="google_translate_element"></a>
      </div>
      <div className="flex flex-col overflow-hidden mt-4 visible lg:invisible -ml-36">
        <a
          onClick={showDrawer}
          className="text-otcblack font-sanRegular lg:text-lg hover:text-white hover:bg-primary cursor-pointer p-2 rounded-sm"
        >
          <FaAlignJustify size={33} />
        </a>
      </div>
    </div>
  );
}
