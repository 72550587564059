import React, { useEffect, useState } from "react";
import MeetLocations from "../../Components/MeetLocations";
import { useLocation } from "react-router-dom";

export default function Information() {
  const [help, setHelp] = useState([]);
  const location = useLocation();

  useEffect(() => {
    console.log("locaiton ", location);
    setHelp(location.state.name);
  }, []);
  const Content = (props) => {
    if (props.value == "Latest COVID travel information") {
      return (
        <div className="w-screen h-[115vh]  ml-24 mt-10 px-4">
          <h1 className="text-xl text-primary font-sanbold">Travel Alert</h1>
          <h1>Face Masks Required</h1>
          <p>
            1. Wearing a mask at the airport, while boarding and during the
            flight is mandatory.
          </p>
          <span>       N.B Children age 5 and older should wear masks.</span>
          <p>
            2. Mask types shall be as per WHO&IATA requirements and
            recommendations.
          </p>
          <span>
                  Suggestion: You can only wear a medical mask (masks with
            one-way valves or vents are not allowed). : Scarfs and bandanas are
            not counted as mask
          </span>
          <p>
            3. As single-use surgical masks must be changed every four hours,
            you are advised to bring enough masks for the duration of your
            travel.
          </p>
          <p>4. Fully Vaccinated are also required to wear Mask.</p>
          <p>
            5. Face shield cannot replace mask. but can be worn in addition to
            mask.
          </p>
          <p
            className="max-w-4xl first-line:uppercase first-line:tracking-widest
                      first-letter:text-4xl first-letter:font-bold first-letter:text-primary
                      first-letter:mr-3 first-letter:float-left font-sanRegular"
          >
            Your safety and well-being are our highest priority. We have been
            taking precautionary measures based on recommendations from WHO,
            ICAO and IATA to protect our passengers, staff and the general
            public from the risk of COVID-19.  As part of the ongoing effort to
            fighting the pandemic, we kindly ask all international and domestic
            passengers to wear face masks during check-in, boarding, in-flight,
            deplaning and at the airport. We ask all passengers to bring their
            own masks or face coverings.  Children under the age of 6 and
            passengers with underlying medical conditions are exempted from the
            above face mask requirement. However, Passengers with underlying
            medical conditions shall be required to present medical certificate
            ascertaining that they cannot wear face masks due to health reasons.
          </p>
          <h1 className="text-xl text-primary font-sanbold">
            Relaxed Refund & Rebooking Policy by Ethiopian Airlines (Revised,
            April 28, 2022)
          </h1>
          <p
            className="max-w-4xl first-line:uppercase first-line:tracking-widest
                      first-letter:text-4xl first-letter:font-bold first-letter:text-primary
                      first-letter:mr-3 first-letter:float-left font-sanRegular"
          >
            This policy is applicable for Passengers holding ET document (071)
            regardless of booking channel. For tickets that are issued on /after
            01 January 21 until 31 May 2022, for travel between 01 Jan 2021
            until 30 September 2022, Unlimited free date change is allowed.  For
            tickets that are to be issued on /after 01 Jun 2022 date change fee
            applies as per the fare rule on system. If for any   reason,
            Passengers are unable to travel on originally scheduled travel date,
            passengers must cancel reservation at least   24 hours before
            departure to avoid No-Show Fee.  Change requests can be performed
            through the Ethiopian Airlines sales offices, Global Call and
            Interaction Centre   (GCIC), and the agencies from which the ticket
            was purchased.
          </p>

          <h1 className="text-xl text-primary font-sanbold">Refunds:</h1>
          <p
            className="max-w-4xl first-line:uppercase first-line:tracking-widest
                      first-letter:text-4xl first-letter:font-bold first-letter:text-primary
                      first-letter:mr-3 first-letter:float-left font-sanRegular"
          >
            • Refunds & all other related rules will be governed by the
            fare/system rule. For flights cancelled by the airline, please
            always be guided by the service recovery policy of the company.  All
            other rules and conditions apply per system.   N.B. Any local
            customer protection laws, DOT, EU, ICPA, DGCA, South Africa, Israel
            & Brazil regulations supersedes & have precedence over this policy.
          </p>

          <div className="flex flex-row">
            <a
              href="https://www.ethiopianairlines.com/aa/travel-updates/updates-on-covid-19(coronavirus)"
              className="pt-10 text-primary hover:text-primary"
            >
              https://www.ethiopianairlines.com/aa/travel-updates/updates-on-covid-19(coronavirus)
            </a>
            {/* <a className="p-10 text-green-700  hover:text-primary">
            secondlink@mail.com
          </a>
          <a className="p-10 text-black  hover:text-primary">
            thridlink@gmail.com
          </a>
          <a className="p-10 text-red-500  hover:text-primary">
            foruthlink@gmail.com
          </a>
          <a className="p-10  hover:text-primary">fithlik@gmail.com</a> */}
          </div>
        </div>
      );
    } else if (props.value == "Get VISA") {
      return (
        <div className="w-screen h-[120vh]  ml-24 mt-10 px-4">
          <h1 className="text-xl text-primary font-sanbold">
            Ethiopia eVisa Information
          </h1>
          <p
            className="max-w-4xl first-line:uppercase first-line:tracking-widest
                      first-letter:text-4xl first-letter:font-bold first-letter:text-primary
                      first-letter:mr-3 first-letter:float-left font-sanRegular"
          >
            The Ethiopia eVisa was introduced in 2017 by the Main Department of
            Immigration and Nationality Affairs of Ethiopia to  facilitate entry
            into the country for Touristic purposes. The eVisa for Ethiopia is a
            single-entry authorization that allows one entry into the country.
             The eVisa is valid for 30 or 90 days, depending on the applicant’s
            selection during the application. An approved eVisa allows the
            traveler to enter Ethiopia via Bole International Airport in Addis
            Ababa. Eligible citizens are able to complete the Ethiopia eVisa
            online application form and follow the simple instructions to get an
            eVisa for Ethiopia. Applicants are required to complete the Ethiopia
            visa application form with basic personal and passport information
            to receive an approved Ethiopia eVisa via email. The Ethiopia eVisa
            is available for a short-term stays for tourism in the country.
            Those wishing to visit the country for other purposes are required
            to contact the nearest Ethiopia Embassy or Consulate.
          </p>
          <h1 className="text-xl text-primary font-sanbold">
            Ethiopia eVisa Requirements
          </h1>
          <p>
            Applicants need the following to submit an Ethiopia Tourist visa
            online application form:
          </p>

          <li className="font-sanbold">
            Passport valid for at least 6 months from the intended entry date to
            Ethiopia
          </li>
          <li className="font-sanbold">
            Passport image of the biographical page
          </li>
          <li className="font-sanbold">
            A passport-style photograph of the applicant
          </li>
          <li className="font-sanbold">
            Credit or debit card to pay the Ethiopia eVisa fee
          </li>
          <li className="font-sanbold">
            Current email address to receive the approved the Ethiopia eVisa
          </li>
          <h1 className="text-lg mt-24 font-sanbold text-primary">
            More information is available in the Ethiopia eVisa guide in the FAQ
            section below
          </h1>

          <img
            src={require("../../assets/airlines.jpeg")}
            className="w-[40vw] h-[40vh] rounded-xl"
          />

          <div className="flex flex-row">
            <a className="pt-10 text-primary hover:text-primary">
              https://www.evisa.gov.et/visa/apply
            </a>
            {/* <a className="p-10 text-green-700  hover:text-primary">
            secondlink@mail.com
          </a>
          <a className="p-10 text-black  hover:text-primary">
            thridlink@gmail.com
          </a>
          <a className="p-10 text-red-500  hover:text-primary">
            foruthlink@gmail.com
          </a>
          <a className="p-10  hover:text-primary">fithlik@gmail.com</a> */}
          </div>
        </div>
      );
    } else if (props.value == "Fly directly to Finfine") {
      return <div className="h-[50vh]"></div>;
    } else if (props.value == "FAQ") {
      return <MeetLocations />;
    } else {
      return null;
    }
  };

  return (
    <div>
      <div className="w-screen h-[40vh] bg-white font-sanRegular p-24 flex flex-row justify-between">
        <div className="bg-primary h-[23vh] rounded-lg shadow-lg shadow-black z-0 p-2">
          <h1 className="text-2xl text-white underline font-sanbold">
            {location.state.name.name}
          </h1>
          <p className=" text-sm max-w-4xl text-white font-sanRegular">
            {location.state.name.description}
          </p>
        </div>
        <img
          src={location.state.name.image}
          className="w-[70vh] h-[23vh] object-cover rounded-lg"
        />
      </div>
      <Content value={location.state.name.name} />
    </div>
  );
}
