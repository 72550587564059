import { createChatBotMessage } from "react-chatbot-kit";

const config = {
  botname: "visitOromia bot",
  initialMessages: [
    createChatBotMessage(
      `Hello, I am an automated customer assistant for visitoromia`
    ),
  ],
  customStyles: {
    botMessageBox: {
      backgroundColor: "black",
    },
    chatButton: {
      backgroundColor: "red",
      padding: "2%",
    },
  },
};

export default config;
