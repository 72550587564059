import React, { useEffect, useState } from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Hero from "./Innercomponents/hero";
import PopularDestination from "./PopularDestination";
import Events from "./Innercomponents/events";
import News from "./News";
import { useNavigate } from "react-router-dom";
import two from "../assets/raft1.png";
import three from "../assets/eventsKid.jpeg";
import one from "../assets/first.jpeg";
import Footer from "./Footer";
import Blog from "./Innercomponents/Blog";
import { FaAngleRight } from "react-icons/fa";
import Zoom from "react-reveal/Zoom";

export default function Home() {
  const [svg, setSVG] = useState([]);
  const [loaded, setLoaded] = useState(false);

  let history = useNavigate();

  const handleClick = (name) => {
    history("/culture", {
      state: { name: name },
    });
  };

  const svgGetter = async () => {
    try {
      const response = await fetch("https://otc.visitoromia.org/api/svg_data/");
      const res = await response.json();

      setSVG(res);
      setLoaded(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    svgGetter();
  }, []);
  return (
    <div className="flex flex-1 flex-col  scroll-smooth w-screen">
      <Hero />
      <PopularDestination />
      <Zoom>
        <div className="flex flex-col lg:flex-row h-[auto] w-screen justify-around align-middle mt-20 lg:-mt-4 xl:mt-0 2xl:mt-2">
          <div className="flex flex-col lg:flex-col lg:-mt-40 -mt-44 w-[100vh] lg:ml-24 mx-2">
            {loaded ? (
              <div>
                <div>
                  <img src={svg[0].image} className="w-[120vw] lg:w-[100vh]" />
                </div>
                <div className="lg:-mt-20">
                  <h1 className="text-primary lg:text-2xl lg:mt-24 font-sanbold text-lg font-extrabold lg:font-normal 2xl:text-4xl">
                    {svg[0].title}
                  </h1>
                  <p className="font-sanRegular text-otcblack lg:text-sm text-xs max-w-[92vw] lg:max-w-xl 2xl:max-w-3xl pt-4 2xl:text-xl  ">
                    {svg[0].description}
                  </p>
                  <button
                    onClick={() => handleClick(svg[0])}
                    className="text-xs 2xl:text-xl lg:text-sm font-sanRegular font-extralight  bg-primary text-white p-2 rounded-lg h-10 mt-2 cursor-pointer"
                  >
                    Read More{" "}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col-reverse lg:flex-col lg:-mt-32 mb-10 w-[100vh] ml-2 lg:ml-0 lg:mr-24 ">
            {loaded ? (
              <div className="flex flex-col-reverse lg:flex-col">
                <div>
                  <h1 className="text-primary font-extrabold  lg:text-2xl lg:mt-24 font-fontbold text-lg 2xl:text-4xl">
                    {svg[1].title}
                  </h1>
                  <p className=" font-sanRegular text-xs lg:text-sm lg:-mt-2 text-otcblack max-w-[94vw] lg:pt-4 2xl:text-xl 2xl:max-w-3xl lg:max-w-2xl ">
                    {svg[1].description}
                  </p>
                  <button
                    onClick={() => handleClick(svg[1])}
                    className="text-xs lg:text-sm 2xl:text-xl font-extralight lg:-mt-2 font-sanRegular  bg-primary text-white p-2 rounded-lg h-10 mt-10 cursor-pointer "
                  >
                    Read More{" "}
                  </button>
                </div>
                <div>
                  <img src={svg[1].image} className="w-[50vh] lg:w-[100vh]" />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Zoom>
      <div
        className="w-screen lg:h-[auto] mt-0"
        style={{ backgroundImage: `url(${one})`, backgroundSize: "cover" }}
      >
        <Blog />
      </div>
      <News />
      <Events />
    </div>
  );
}
