import React, { useState, useEffect, Suspense } from "react";
import { FaAngleRight } from "react-icons/fa";
import { Image, Modal } from "antd";

import { map } from "lodash";

export default function () {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [imagesD, setImageD] = useState([]);
  const [actives, setActivits] = useState("");
  const [cat, setCat] = useState([]);
  const [i, setI] = useState(15);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (item) => {
    setActivits(item);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const catList = async () => {
    try {
      const response = await fetch("https://otc.visitoromia.org/api/dest_list");
      const resp = await response.json();
      setCat(resp);
    } catch (err) {
      console.log(err);
    }
  };
  const getHeader = async (name) => {
    try {
      const response = await fetch(
        `https://otc.visitoromia.org/api/search_cat/?search=${name}`
      );
      const res = await response.json();
      var imageData = [];
      setData(res);
      res.map((item, index) => {
        item.image.map((ins, ind) => imageData.push(ins));
      });
      setImageD(imageData);
      setLoaded(true);
    } catch (e) {}
  };
  const next = () => {
    setI(i + 1);
  };
  useEffect(() => {
    catList();
    getHeader("National Parks");
  }, []);

  return (
    <div>
      {loaded ? (
        <>
          <div className="w-screen h-[auto]  bg-white p-2 lg:pl-24 flex flex-row justify-between mb-10  mt-4">
            <div>
              <h1 className="text-2xl text-primary font-sanbold">
                {" "}
                Destination gallary
              </h1>
              <p className="uppercase text-sm max-w-4xl text-primary">
                Explore our gallary filled with wonders of Oromia
              </p>
              <div>
                {cat.map((item, index) => (
                  <a
                    onClick={() => getHeader(item.name)}
                    key={index}
                    className="hover:text-white hover:bg-primary text-primary p-2 border-[1px] mr-2 border-primary rounded-lg"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="w-screen">
            <div className=" ml-2 lg:ml-24 mb-[20vh] lg:mb-[10vh] flex flex-col lg:flex-row">
              <div className="flex flex-col lg:flex-row justify-around ">
                <div className="w-[94vw] flex flex-row overflow-x-hidden flex-wrap">
                  {imagesD.map((item, index) => (
                    <img
                      onClick={() => showModal(item.image)}
                      src={item.image}
                      className="max-w-[28vw] max-h-[80vh] mr-2 mb-2 shadow-lg shadow-black object-cover rounded-lg"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="w-screen h-[60vh]">
          <h1 className="text-center text-2xl text-primary mt-20">Loading</h1>
        </div>
      )}
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={"80vw"}
      >
        <img src={actives} className="w-[100vw] -mt-24" />
      </Modal>
    </div>
  );
}
