import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Carousel } from "antd";

export default function PackageDetail() {
  const [news, setNews] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [eventsall, setEvents] = useState([]);
  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }
  const location = useLocation();
  const setNewsNew = (value) => {
    setNews(value);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    console.log(location);
    setNews(location.state.name);
    var list = [];
    // setEvents(location.state.others);
    location.state.other.filter((state) => {
      if (state.id != location.state.name.id) {
        list.push(state);
      }
    });
    setEvents(list);
    setLoaded(true);
  }, []);

  return (
    <div>
      {loaded ? (
        <div className="flex flex-col -mb-20">
          <div className="w-screen h-[auto] bg-white pb-10 p-2 pl-24 pr-24 flex flex-col-reverse lg:flex-row justify-between ">
            <div className="backdrop-blur-sm bg-primary p-2 rounded-lg shadow-lg shadow-black mr-2 lg:w-[40vw]">
              <h3 className="text-white font-sanbold text-lg">
                {news.catagory}
              </h3>
              <h1 className="text-2xl text-white ">{news.title}</h1>
              <p className="capitalize text-sm max-w-4xl text-white font-sanRegular">
                {news.description}
              </p>
              <h2 className="text-white font-sanbold">
                Duration:- {news.duration}
              </h2>
              <h2 className="text-white font-sanbold">
                Transportation:- {news.transportation}
              </h2>
              <h2 className="text-white font-sanbold">
                Tour Code:- {news.tour_code}
              </h2>
              <a
                href={news.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-black hover:text-white font-sanbold text-lg"
              >
                Link To Providers
              </a>

              <h1 className="text-white font-sanbold">Main Destination</h1>
              <Carousel effect="fade" autoplay={true} className="w-[35vw]">
                {news.destination[0].image.map((item, index) => (
                  <img
                    key={index}
                    src={item.image}
                    className="w-[100vw] h-[50vh] rounded-lg shadow-lg shadow-black"
                  />
                ))}
              </Carousel>
            </div>

            <img
              src={news.image}
              className="w-[50vw] min-h-[50vh] max-h-[90vh] object-cover rounded-lg"
            />
          </div>
          <div className="divide-y divide-teal-400 md:divide-pink-400"></div>

          <h1 className="ml-24 font-sanbold text-4xl text-primary mt-0 sticky top-0">
            More Packages
          </h1>
          <div className="flex flex-col mt-10 ml-24 mb-10">
            {eventsall.map((item, index) => (
              <div className="flex flex-row mb-4" key={index}>
                <img
                  src={item.image}
                  className="w-[50vw] h-[50vh] object-cover  -mt-10 rounded-xl shadow-lg shadow-black mr-10 mb-20"
                />
                <div className="w-[35vw]">
                  <h1 className="text-2xl text-primary font-sanbold">
                    {item.title}
                  </h1>

                  <p className="capitalize text-sm max-w-3xl text-otcblack font-sanRegular max-h-20 overflow-hidden">
                    {item.description}
                  </p>
                  <p>...</p>
                  <a
                    onClick={() => setNewsNew(item)}
                    className="bg-primary text-white hover:text-black hover:bg-white hover:border-2 hover:border-primary p-2 rounded-lg"
                  >
                    Explore
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
