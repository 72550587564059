import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ActivityDetail() {
  const location = useLocation();
  console.log(location);

  return (
    <div>
      <div></div>
    </div>
  );
}
